import { clientApi } from "./clientApi"

const ordersService = {
  downloadOrder: {
    key: "downloadOrder",
    fn: async (orderId: any) => {
      try {
        const { data } = await clientApi.get(`/orders/pdf/${orderId}`, {
          responseType: "blob",
        })
        return data
      } catch (error: any) {
        throw new Error(error.response?.data?.message)
      }
    },
  },
  getOrder: {
    key: "getOrder",
    fn: async (orderId: any) => {
      try {
        const { data } = await clientApi.get(`/orders/${orderId}`)
        return data
      } catch (error: any) {
        throw new Error(error.response?.data?.message)
      }
    },
  },
  listOrders: {
    key: "listOrders",
    fn: async ({ page, ...filters }: any) => {
      try {
        const { data } = await clientApi.get("/orders", {
          params: {
            page,
            ...filters,
          },
        })
        return data
      } catch (error: any) {
        throw new Error(error.response?.data?.message)
      }
    },
  },
  deleteOrder: {
    key: "deleteOrder",
    fn: async (orderId: any) => {
      try {
        await clientApi.delete(`/orders/${orderId}`)
      } catch (error: any) {
        throw new Error(error.response?.data?.message)
      }
    },
  },
  updateOrder: {
    key: "updateOrder",
    fn: async (data: any) => {
      try {
        await clientApi.put(`/orders/${data.id}`, data)
      } catch (error: any) {
        throw new Error(error.response?.data?.message)
      }
    },
  },
}
export { ordersService }
