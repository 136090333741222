import ImageUploader from "@components/NewProduct/Images"
import Informations from "@components/NewProduct/Informations"
import Loading from "@components/Loading"
import React, { useEffect, useState } from "react"
import Sales from "@components/NewProduct/Sales"
import Status from "@components/NewProduct/Status"
import UnderlineTitle from "@components/UnderlineTitle"
import useStore from "@store"
import { fieldsProduct } from "@constants/Products"
import { formatNumberToMoney, formatStringMoneyToNumber } from "@utils/money"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { newProductSchema } from "@schemas/Products"
import { privateRoutes } from "@constants/Routes"
import { productsService } from "@services/Products"
import { queryClient } from "@services/queryClient"
import { segmentsService } from "@services/Segments"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate, useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"

interface ImageState {
  id: string
  fileDetails: File
  url?: string
}

const NewProduct: React.FC = () => {
  const navigate = useNavigate()
  const { userDetail } = useStore()
  const { productId } = useParams()

  const [isLoading, setIsLoading] = useState(productId !== undefined)
  const [removedImages, setRemovedImages] = useState<string[]>([])
  const [images, setImages] = useState<ImageState[]>([])

  const { data: customFields = [] } = useQuery({
    queryKey: [segmentsService.getSegmentsCustomFields.key],
    queryFn: () =>
      segmentsService.getSegmentsCustomFields.fn(userDetail?.segment_id || ""),
  })

  const schema = newProductSchema(customFields)

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
    trigger,
    setValue,
  } = methods
  const { append: appendSales } = useFieldArray({ name: "sales", control })

  const allFields = fieldsProduct.concat(
    customFields.map((field) => ({
      label: field.label,
      key: field.key,
      info: field.description || "",
      placeholder: field.label,
      size: "full",
      isRequired: field.is_required,
      masks: field.mask as any,
    }))
  )

  const { mutate: createProduct, isPending: isCreatePending } = useMutation({
    mutationFn: productsService.createProduct.fn,
    onSuccess: () => {
      navigate(privateRoutes.products)
      toast.success("Produto criado com sucesso!")
    },
    onError: (error) => {
      toast.error("Erro ao criar produto: " + error.message)
    },
  })

  const { mutate: updateProduct, isPending: isUpdatePending } = useMutation({
    mutationFn: productsService.updateProduct.fn,
    onSuccess: () => {
      navigate(privateRoutes.products)
      toast.success("Produto atualizado com sucesso!")
    },
    onError: (error) => {
      toast.error("Erro ao atualizar produto: " + error.message)
    },
  })

  const loadProductDetails = async (
    productId: string,
    setValue: any,
    appendSales: any,
    trigger: any,
    setImages: any,
    setIsLoading: (value: boolean) => void
  ) => {
    try {
      const product = await productsService.getProductDetails.fn(productId)
      if (product) {
        setValue("name", product.name)
        setValue("salePrice", formatNumberToMoney(product.sale_price))
        setValue("price", formatNumberToMoney(product.price))
        setValue("isAvailable", product.is_available)
        setValue("isRelease", product.is_release)

        product.sales?.forEach((sale: any) => {
          appendSales({
            from: sale.from,
            price: sale.price,
            id: Math.random(),
          })
        })

        product.custom_fields?.forEach((field: any) => {
          setValue(field.key, field.value)
        })

        trigger()

        setImages(
          product.image_urls?.map((url: string) => ({
            id: Math.random().toString(36).substr(2, 9),
            url,
          })) || []
        )

        setIsLoading(false)
      }
    } catch (error) {
      console.error("Erro ao carregar detalhes do produto:", error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (productId) {
      loadProductDetails(
        productId as string,
        setValue,
        appendSales,
        trigger,
        setImages,
        setIsLoading
      )
    }
  }, [productId])

  const isEditing = productId !== undefined
  const hasActionInProgress = isCreatePending || isUpdatePending
  const creationWasEnabled = isValid && !isCreatePending && images.length > 0

  const formatFormDataToSubmit = (data: any) => {
    return {
      name: data.name,
      price: formatStringMoneyToNumber(data.price),
      sale_price: formatStringMoneyToNumber(data.salePrice),
      is_available: data.isAvailable,
      is_release: data.isRelease,
      sales: data?.sales?.map((sale: any) => ({
        from: sale.from,
        price: formatStringMoneyToNumber(sale.price),
      })),
      custom_fields: customFields.map((field) => ({
        key: field.key,
        value: data[field.key],
      })),
      files: images.map((image) => image.fileDetails),
    }
  }

  const handleFormSubmit = (data: any) => {
    const formattedData = formatFormDataToSubmit(data)

    queryClient.resetQueries({
      queryKey: [productsService.listProducts.key],
    })

    if (isEditing) {
      updateProduct({
        id: productId,
        removed_image_urls: removedImages,
        ...formattedData,
      })
    } else {
      createProduct(formattedData)
    }
  }

  return (
    <FormProvider {...methods}>
      <div className="w-full flex justify-between items-center mb-8">
        <UnderlineTitle title="Novo produto" />
        <div className="w-fit flex justify-end mt-4 gap-3">
          <SimpleButton
            label="Voltar"
            onClick={() => navigate(privateRoutes.products)}
            mode="outline"
            disabled={hasActionInProgress}
          />
          <SimpleButton
            label={isEditing ? "Salvar alterações" : "Criar produto"}
            disabled={!creationWasEnabled}
            isLoading={hasActionInProgress}
            onClick={handleSubmit(handleFormSubmit)}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <Loading iconLoading="Package" />
        </div>
      ) : (
        <div className="w-full grid grid-cols-2 gap-4">
          <Informations
            fields={allFields}
            hasActionInProgress={hasActionInProgress}
          />
          <ImageUploader
            images={images}
            hasActionInProgress={hasActionInProgress}
            setImages={setImages}
            setRemovedImages={setRemovedImages}
          />
          <Status hasActionInProgress={hasActionInProgress} />
          <Sales hasActionInProgress={hasActionInProgress} />
        </div>
      )}
    </FormProvider>
  )
}

export default NewProduct
