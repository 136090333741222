import * as yup from "yup"
import GenericModal from "@components/GenericModal"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import Loading from "@components/Loading"
import React, { useEffect, useState } from "react"
import ToggleInput from "@components/Inputs/ToggleInput"
import { clientsService } from "@services/Clients"
import { IClientModal } from "@interfaces/Components/Modals"
import { requiredFieldMessage } from "@constants/index"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"

const schema = yup.object({
  name: yup.string().required(requiredFieldMessage),
  email: yup.string().email("Email inválido").required(requiredFieldMessage),
  cnpj: yup
    .string()
    .required(requiredFieldMessage)
    .min(14, "Digitos insuficientes"),
  phone: yup.string().test("phone", "Telefone inválido", (value) => {
    if (!value) return true

    if (value.length < 15) return false

    return true
  }),
  isActive: yup.boolean().required(requiredFieldMessage),
})

const ClientModal: React.FC<IClientModal> = ({
  isOpen = true,
  isPendingRequest,
  isEditing,
  isLoadind,
  onFinished,
  onClose,
}) => {
  const [params, setParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(isLoadind)
  const selectedClientId = params.get("clientId")

  const {
    register,
    formState: { errors, isValid },
    setValue,
    reset,
    handleSubmit,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  })

  useEffect(() => {
    if (!selectedClientId || !isOpen || !isEditing) return

    clientsService.clientDetails.fn(selectedClientId).then((data) => {
      setValue("name", data.name)
      setValue("email", data.email)
      setValue("phone", data.phone)
      setValue("cnpj", data.cnpj)
      setValue("isActive", data.is_active)

      trigger()
      setIsLoading(false)
    })
  }, [selectedClientId, isOpen, isEditing])

  const onCloseModal = () => {
    onClose()
    reset({
      name: "",
    })
    setParams({})
    setIsLoading(true)
  }
  const hasSomeFieldFilled = Object.values(watch()).some((value) => !!value)

  return (
    <GenericModal
      title={isEditing ? "Editar cliente" : "Criar novo cliente"}
      label={`Preencha os campos abaixo para ${
        isEditing ? "editar um cliente" : "criar um novo cliente"
      }`}
      icon="Users"
      isOpen={isOpen}
      onClose={onCloseModal}
      disabledClickOutside={hasSomeFieldFilled}
    >
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-64">
          <Loading iconLoading="Users" />
        </div>
      ) : (
        <div className="w-full max-w-md flex flex-col gap-1">
          <div className="w-full flex gap-4">
            <InputWithLabel
              label="Nome do cliente"
              placeholder="Nome do cliente"
              size="full"
              register={register("name")}
              error={errors.name?.message}
              masks="onlyLetters"
              handleClearFormFields={() => setValue("name", "")}
            />
          </div>
          <div className="w-full flex gap-4">
            <InputWithLabel
              label="Email"
              placeholder="Email"
              size="full"
              register={register("email")}
              error={errors.email?.message}
              handleClearFormFields={() => setValue("email", "")}
            />
          </div>
          <div className="w-full flex gap-4">
            <InputWithLabel
              label="Telefone"
              placeholder="Telefone"
              size="full"
              masks="phone"
              register={register("phone")}
              error={errors.phone?.message}
              handleClearFormFields={() => setValue("phone", "")}
            />
            <InputWithLabel
              label="CNPJ"
              placeholder="CNPJ"
              size="full"
              masks="cnpj"
              register={register("cnpj")}
              error={errors.cnpj?.message}
              info="Verifique se o CNPJ está correto, pois ele será utilizado pelo cliente para validação de acesso"
              handleClearFormFields={() => setValue("cnpj", "")}
            />
          </div>

          <ToggleInput
            register={register}
            name="isActive"
            title="Status"
            description="Esse cliente pode fazer compras?"
            info="Ao marcar essa opção, o cliente poderá fazer compras no sistema"
          />
          <div className="w-full flex justify-end mt-4">
            <SimpleButton
              label={isEditing ? "Salvar alterações" : "Criar cliente"}
              disabled={!isValid}
              isLoading={isPendingRequest}
              onClick={handleSubmit((data) => {
                onFinished({
                  id: selectedClientId,
                  ...data,
                })
              })}
            />
          </div>
        </div>
      )}
    </GenericModal>
  )
}

export default ClientModal
