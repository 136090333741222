import { MinusCircle, PlusCircle, TrashSimple } from "phosphor-react"
import { formatNumberToMoney } from "@utils/money"
import { ICartItem } from "@interfaces/Components/Ecommerce"

interface CartItemProps {
  item: ICartItem
  handleUpdateQuantity: (item: ICartItem, quantity: number) => void
  handleRemoveCart: (item: ICartItem) => void
}

const CartItem: React.FC<CartItemProps> = ({
  item,
  handleUpdateQuantity,
  handleRemoveCart,
}) => {
  return (
    <div className="flex items-center w-full">
      <img
        src={item?.image_urls?.[0]}
        alt={`Produto - ${item.name}`}
        className="w-24 h-24"
      />
      <div className="flex flex-col items-start justify-center gap-3 h-full ml-2 text-base">
        <span className="font-medium text-lg">{item.name}</span>
        <div className="flex items-center">
          <MinusCircle
            size={24}
            className="text-primary-300 cursor-pointer"
            weight="fill"
            onClick={() => handleUpdateQuantity(item, item.quantity - 1)}
          />
          <input
            type="text"
            className="max-w-[60px] text-center mx-2 border border-gray-400 rounded-md"
            value={item.quantity}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10)
              if (value > 0) handleUpdateQuantity(item, value)
            }}
          />
          <PlusCircle
            size={24}
            className="text-primary-300 cursor-pointer"
            weight="fill"
            onClick={() => handleUpdateQuantity(item, item.quantity + 1)}
          />
          <div className="divider divider-horizontal before:bg-gray-300 after:bg-gray-300"></div>
          <TrashSimple
            size={24}
            className="text-primary-300 cursor-pointer"
            weight="fill"
            onClick={() => handleRemoveCart(item)}
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center text-lg font-medium ml-auto">
        {item.lastPrice !== item.price && (
          <span className="text-gray-400 line-through text-base">
            {formatNumberToMoney(item.lastPrice)}
          </span>
        )}
        {formatNumberToMoney(item.price)}
      </div>
    </div>
  )
}

export default CartItem
