import clsx from "clsx"
import React, { useCallback } from "react"
import { ImageUploaderProps } from "@interfaces/Pages/Products"
import { Plus, X } from "phosphor-react"
import { toast } from "sonner"
import { useDropzone } from "react-dropzone"
import Card from "@components/Card"

const ImageUploader: React.FC<ImageUploaderProps> = ({
  images,
  hasActionInProgress,
  setImages,
  setRemovedImages,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (images.length >= 3) return

      setImages((prev) => [
        ...prev,
        ...acceptedFiles.map((file) => ({
          id: Math.random().toString(36).substr(2, 9),
          fileDetails: file,
        })),
      ])
    },
    [images, setImages]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    validator: (file) => {
      if (file.size > 150 * 1024) {
        return {
          code: "file-too-large",
          message: `O arquivo ${file.name} é muito grande, o tamanho máximo é 150KB`,
        }
      }
      if (images.length >= 3) {
        return {
          code: "max-files",
          message: "Máximo de 3 imagens",
        }
      }
      if (images.some((image) => image.fileDetails?.name === file.name)) {
        return {
          code: "file-already-exists",
          message: `O arquivo ${file.name} já foi adicionado`,
        }
      }
      return null
    },
    onError: (error) => {
      toast.error("Erro ao adicionar imagem: " + error.message)
    },
    onDropRejected: (files) => {
      toast.error(
        "Erro ao adicionar imagem: " + files[0].errors[0].message || ""
      )
    },
    maxFiles: 3,
  })

  return (
    <Card className="w-full min-h-[220px] flex flex-col">
      <div className="w-full flex justify-between items-center mb-4">
        <div className="w-full flex gap-2 justify-start items-center">
          <p className="text-lg font-semibold text-primary-500">Imagen(s)</p>
          <p className="text-base text-neutral-500">( Máximo de 3 imagens )</p>
        </div>
        <div {...getRootProps()} className="cursor-pointer">
          <Plus
            size={32}
            weight="fill"
            className={clsx(
              "text-primary-500",
              (images.length >= 3 || hasActionInProgress) &&
                "opacity-30 cursor-not-allowed"
            )}
            onClick={(e) => {
              if (images.length >= 3 || hasActionInProgress) e.stopPropagation()
            }}
          />
        </div>
      </div>
      <div className="w-full h-full p-2 flex justify-center items-center my-auto border-[2px] border-dashed border-neutral-300 rounded-lg">
        <div
          className="w-full h-full flex flex-col justify-center items-center"
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {images.length > 0 ? (
            <div
              className="w-full h-full flex justify-start items-center gap-4 overflow-x-auto"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {images.map((file) => (
                <div
                  key={file.id}
                  className="w-1/3 h-64 relative flex justify-center items-center"
                >
                  <div className="absolute bg-neutral-950 bg-opacity-50 p-1 rounded-lg top-1 left-1 flex justify-center items-center gap-2">
                    <X
                      size={16}
                      weight="bold"
                      className={clsx(
                        "text-white",
                        hasActionInProgress && "opacity-30 cursor-not-allowed",
                        !hasActionInProgress &&
                          "hover:text-red-500 cursor-pointer"
                      )}
                      onClick={() => {
                        if (hasActionInProgress) return

                        if (file.url) {
                          setRemovedImages((prev) => [...prev, file.url!])
                        }

                        setImages((prev) =>
                          prev.filter((image) => image.id !== file.id)
                        )
                      }}
                    />
                    {(file.fileDetails?.name || file.fileDetails?.size) && (
                      <div>
                        {file.fileDetails?.name && (
                          <p className="text-xs text-neutral-100 max-w-48 truncate">
                            {file.fileDetails?.name}
                          </p>
                        )}
                        {file.fileDetails?.size && (
                          <p className="text-xs text-neutral-100">
                            Tamanho:{" "}
                            {(file.fileDetails?.size / 1000).toFixed(0)} KB
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <img
                    key={file.fileDetails?.name}
                    src={file?.url || URL.createObjectURL(file.fileDetails)}
                    alt={file.fileDetails?.name}
                    className="w-full h-64 object-cover rounded-lg"
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full min-h-[150px] flex flex-col justify-center items-center">
              <p className="text-lg font-semibold text-neutral-500">
                Arraste e solte as imagens aqui
              </p>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default ImageUploader
