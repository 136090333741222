import { IListProducts } from "@interfaces/Services"
import { clientApi } from "./clientApi"
import { toast } from "sonner"

const ecommerceService = {
  listProducts: {
    fn: async ({
      slug,
      name,
      currentPage,
      isRelease,
      isSale,
    }: any): Promise<IListProducts> => {
      try {
        const { data } = await clientApi.get(`/products/user/${slug}`, {
          params: {
            page: currentPage,
            name,
            is_release: isRelease ? isRelease : undefined,
            is_on_sale: isSale ? isSale : undefined,
          },
        })

        return {
          ...data,
          data: data.data.filter((product: any) => product.is_available),
        }
      } catch (error: any) {
        toast.error("Erro ao criar: " + error.message)
        throw new Error(error)
      }
    },
    key: "listProducts",
  },
  createOrder: {
    fn: async (data: {
      slug: string
      name: string
      email: string
      cnpj: string
      payment_method: string
      products: any[]
    }) => {
      const response = clientApi.post("/orders", data)

      toast.promise(response, {
        loading: "Enviando pedido...",
        success: "Pedido realizado com sucesso!",
        error: "Erro ao realizar o pedido",
      })

      await response
    },
    key: "createOrder",
  },
}
export { ecommerceService }
