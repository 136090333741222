import React from "react"
import { publicRoutes } from "@constants/Routes"
import FullLogo from "@assets/FullLogo"
import BG from "@assets/bg.png"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { useNavigate } from "react-router-dom"

// import { SimpleButton } from "@components/Buttons/SimpleButton"

const Home: React.FC = ({}) => {
  const navigate = useNavigate()

  return (
    <div className="">
      <nav className="relative container mx-auto p-6">
        <div className="flex items-center justify-between">
          <div className="flex md:hidden">
            <FullLogo size="small" />
          </div>
          <div className="hidden md:flex">
            <FullLogo size="medium" />
          </div>

          <div className="hidden space-x-12 md:flex md:space-x-0 gap-16">
            <a
              href="#"
              className="text-lg font-medium text-gray-900 hover:text-gray-700"
            >
              Planos
            </a>
            <a
              href="#"
              className="text-lg font-medium text-gray-900 hover:text-gray-700"
            >
              Funcionalidades
            </a>
            <a
              href="#"
              className="text-lg font-medium text-gray-900 hover:text-gray-700"
            >
              Depoimentos
            </a>
            <a
              href="#"
              className="text-lg font-medium text-gray-900 hover:text-gray-700"
            >
              Contato
            </a>
          </div>

          <SimpleButton
            label="Entrar"
            className="!font-bold"
            onClick={() => {
              navigate(publicRoutes.signIn)
            }}
          />
        </div>

        <div className="md:hidden">
          <div
            id="menu"
            className="absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md"
          >
            <a href="#">Planos</a>
            <a href="#">Funcionalidades</a>
            <a href="#">Sobre Nós</a>
            <a href="#">Carreiras</a>
            <a href="#">Comunidade</a>
          </div>
        </div>
      </nav>

      <section id="hero">
        <div className="container flex flex-col mb-16 items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row md:justify-between md:items-start md:mb-0">
          <div className="flex flex-col mb-16 space-y-12 md:w-1/2">
            <h1 className="max-w-md text-gray-700 text-4xl font-bold text-center md:text-5xl md:text-left">
              Todos seus pedidos em um só lugar, de forma simples e rápida.
            </h1>
            <p className="max-w-sm text-center text-gray-700 md:text-left">
              Gerencie todos os seus pedidos de forma simples e rápida. Nosso
              software é feito sob medida para equipes de produtos digitais
              modernas.
            </p>
            <div className="flex justify-center md:justify-start">
              <SimpleButton
                size="large"
                label="Cadastre-se"
                className="!font-bold"
                variant="secondary"
                onClick={() => {
                  navigate(publicRoutes.signUp)
                }}
              />
            </div>
          </div>

          <div className="mb-16 md:w-1/3">
            <img src={BG} />
          </div>
        </div>
      </section>

      <section id="features">
        <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row">
          <div className="flex flex-col space-y-12 md:w-1/2">
            <h2 className="max-w-md text-4xl font-bold text-center md:text-left text-gray-700">
              O que diferencia nosso sistema de pedidos?
            </h2>
            <p className="max-w-sm text-center text-gray-700 md:text-left">
              Nosso sistema oferece todas as funcionalidades que sua equipe
              precisa, sem a complexidade. Nosso software é feito sob medida
              para equipes de produtos digitais modernas.
            </p>
          </div>

          <div className="flex flex-col space-y-8 md:w-1/2">
            <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-secondary-500">
                    01
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Acompanhe o progresso dos pedidos
                  </h3>
                </div>
              </div>
              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Acompanhe o progresso dos pedidos
                </h3>
                <p className="text-gray-700">
                  Veja como suas tarefas diárias se encaixam na visão geral.
                  Acompanhe o progresso desde o nível de marcos até os menores
                  detalhes. Nunca perca de vista o panorama geral.
                </p>
              </div>
            </div>

            <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-secondary-500">
                    02
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Relatórios avançados integrados
                  </h3>
                </div>
              </div>
              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Relatórios avançados integrados
                </h3>
                <p className="text-gray-700">
                  Defina estimativas de entrega internas e acompanhe o progresso
                  em direção aos objetivos da empresa. Nosso painel
                  personalizável ajuda você a criar os relatórios necessários
                  para manter os principais interessados informados.
                </p>
              </div>
            </div>

            <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
              <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                <div className="flex items-center space-x-2">
                  <div className="px-4 py-2 text-white rounded-full md:py-1 bg-secondary-500">
                    03
                  </div>
                  <h3 className="text-base font-bold md:mb-4 md:hidden">
                    Tudo o que você precisa em um só lugar
                  </h3>
                </div>
              </div>
              <div>
                <h3 className="hidden mb-4 text-lg font-bold md:block">
                  Tudo o que você precisa em um só lugar
                </h3>
                <p className="text-gray-700">
                  Pare de pular de um serviço para outro para se comunicar,
                  armazenar arquivos, acompanhar tarefas e compartilhar
                  documentos. Nosso sistema oferece uma solução completa de
                  produtividade para equipes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="testimonials">
        <div className="max-w-6xl px-5 mx-auto mt-32 text-center">
          <h2 className="text-4xl font-bold text-center">
            O que nossos clientes dizem?
          </h2>

          <div className="flex flex-col mt-24 md:flex-row md:space-x-6">
            <div className="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3">
              <img
                src="https://placehold.it/50"
                className="w-16 rounded-full"
                alt=""
              />
              <h5 className="text-lg font-bold">Paulo</h5>
              <p className="text-sm text-gray-700">
                "Esse sistema de pedidos superou as expectativas da nossa
                equipe. A capacidade de manter a visibilidade em marcos maiores
                o tempo todo mantém todos motivados.”
              </p>
            </div>

            <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
              <img
                src="https://placehold.it/50"
                className="w-16 rounded-full"
                alt=""
              />
              <h5 className="text-lg font-bold">Patricia</h5>
              <p className="text-sm text-gray-700">
                “Conseguimos cancelar muitas outras assinaturas desde que
                começamos a usar o sistema de pedidos. Não há mais confusão
                entre canais e todos estão muito mais focados.”
              </p>
            </div>

            <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
              <img
                src="https://placehold.it/50"
                className="w-16 rounded-full"
                alt=""
              />
              <h5 className="text-lg font-bold">João</h5>
              <p className="text-sm text-gray-700">
                “Esse sistema de pedidos superou as expectativas da nossa
                equipe. A capacidade de manter a visibilidade em marcos maiores
                o tempo todo mantém todos motivados.”
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="cta" className="bg-secondary-500">
        <div className="container flex flex-col items-center justify-between px-6 py-24 mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0">
          <h2 className="text-5xl font-bold text-center text-white md:text-4xl md:max-w-xl md:text-left">
            Simplifique como você gerencia seus pedidos.
          </h2>

          <div>
            <SimpleButton
              label="Cadastre-se"
              className="!font-bold"
              variant="white"
              onClick={() => {
                navigate(publicRoutes.signUp)
              }}
            />
          </div>
        </div>
      </section>

      <footer className="bg-gray-800">
        <div className="container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0">
          <div className="flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start">
            <div className="mx-auto my-6 text-center text-white md:hidden">
              Copyright © 2022, Todos os Direitos Reservados
            </div>

            <div>
              <FullLogo insideColor="#fff" />
            </div>

            <div className="flex justify-center space-x-4">
              <a href="#">
                <img src="./icon-facebook.svg" alt="" className="h-8" />
              </a>

              <a href="#">
                <img src="./icon-youtube.svg" alt="" className="h-8" />
              </a>

              <a href="#">
                <img src="./icon-twitter.svg" alt="" className="h-8" />
              </a>

              <a href="#">
                <img src="./icon-pinterest.svg" alt="" className="h-8" />
              </a>

              <a href="#">
                <img src="./icon-instagram.svg" alt="" className="h-8" />
              </a>
            </div>
          </div>

          <div className="flex justify-around space-x-32">
            <div className="flex flex-col space-y-3 text-white">
              <a href="#" className="hover:text-secondary-400">
                Home
              </a>
              <a href="#" className="hover:text-secondary-400">
                Planos
              </a>
              <a href="#" className="hover:text-secondary-400">
                Produtos
              </a>
              <a href="#" className="hover:text-secondary-400">
                Sobre
              </a>
            </div>
            <div className="flex flex-col space-y-3 text-white">
              <a href="#" className="hover:text-secondary-400">
                Carreiras
              </a>
              <a href="#" className="hover:text-secondary-400">
                Comunidade
              </a>
              <a href="#" className="hover:text-secondary-400">
                Política de Privacidade
              </a>
            </div>
          </div>

          <div className="hidden text-white md:block">
            Copyright © {new Date().getFullYear()}, Todos os Direitos
            Reservados
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
