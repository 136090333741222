import Card from "@components/Card"
import ToggleInput from "@components/Inputs/ToggleInput"
import { IStatus } from "@interfaces/Pages/Products"
import React from "react"
import { useFormContext } from "react-hook-form"

const Status: React.FC<IStatus> = ({ hasActionInProgress }) => {
  const { register } = useFormContext()

  return (
    <Card className="w-full max-h-fit min-h-[220px] flex flex-col justify-between">
      <p className="text-lg font-semibold text-primary-500 mb-4">Status</p>
      <div>
        <ToggleInput
          register={register}
          name="isRelease"
          title="Lançamento"
          description="Este produto é um lançamento?"
          info="Ao marcar essa opção, o produto será exibido como um lançamento."
          disabled={hasActionInProgress}
        />
        <ToggleInput
          register={register}
          name="isAvailable"
          title="Disponibilidade"
          description="Esta disponível para venda?"
          info="Ao marcar essa opção, o produto será exibido como disponível para venda."
          disabled={hasActionInProgress}
        />
      </div>
    </Card>
  )
}

export default Status
