import * as yup from "yup"
import CartDrawer from "@components/Ecommerce/CartDrawer"
import FilterBar from "@components/Ecommerce/Filterbar"
import FullLogo from "@assets/FullLogo"
import Loading from "@components/Loading"
import Pagination from "@components/Pagination"
import ProductGrid from "@components/Ecommerce/ProductGrid"
import React, { useState } from "react"
import SearchBar from "@components/Ecommerce/SearchBar"
import useLocalStorage from "@hooks/useLocalStorage"
import { ecommerceService } from "@services/Ecommerce"
import { ICartItem } from "@interfaces/Components/Ecommerce"
import { IListProducts, IProductResponse } from "@interfaces/Services"
import { useMutation, useQuery } from "@tanstack/react-query"
import { ShoppingBag } from "phosphor-react"
import { toast } from "sonner"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"

const formSchema = yup.object({
  name: yup.string().required("Nome do cliente é obrigatório"),
  email: yup.string().email("Email inválido").required("Email é obrigatório"),
  cnpj: yup.string().required("CNPJ é obrigatório").min(14, "CNPJ inválido"),
  payment_method: yup.string().required("Método de pagamento é obrigatório"),
})

const Ecommerce: React.FC = ({}) => {
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)

  const slug = searchParams.get("slug")

  const [currentPage, setCurrentPage] = useState(1)

  const [storagedCart, setStoragedCart] = useLocalStorage<ICartItem[]>(
    `cart-${slug}`,
    []
  )

  const [search, setSearch] = useState("")
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [isFinishingOrder, setIsFinishingOrder] = useState(false)

  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const total = storagedCart.reduce((acc, item) => {
    return acc + item.price * item.quantity
  }, 0)

  const handleAddCart = (item: IProductResponse) => {
    const existingItem = storagedCart.find(
      (cartItem) => cartItem.id === item.id
    )

    const lastPrice = item.price
    let price = item.price

    if (item.sale_price && item.sale_price !== 0) {
      price = item.sale_price
    }

    if (existingItem) {
      const updatedCart = storagedCart.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity + 1, price }
          : cartItem
      )

      setStoragedCart(updatedCart)
    } else {
      setStoragedCart([
        ...storagedCart,
        {
          id: item.id,
          name: item.name,
          price,
          lastPrice,
          quantity: 1,
          image_urls: item.image_urls,
        },
      ])
    }

    toast.success("Produto adicionado ao carrinho", {
      duration: 700,
    })
  }

  const handleRemoveCart = (item: ICartItem) => {
    setStoragedCart(storagedCart.filter((cartItem) => cartItem.id !== item.id))
    toast.warning("Produto removido do carrinho", {
      duration: 700,
    })
  }

  const handleUpdateQuantity = (item: ICartItem, quantity: number) => {
    if (quantity <= 0) {
      setStoragedCart(
        storagedCart.filter((cartItem) => cartItem.id !== item.id)
      )
      return
    }

    const existingProduct = products?.data.find(
      (product) => product.id === item.id
    )

    if (!existingProduct) {
      return
    }

    const { sales, sale_price } = existingProduct

    let updatedPrice = sale_price || item.price

    if (sales?.length > 0) {
      const sortedSales = sales
        .sort((a, b) => b.from - a.from)
        .map((sale) => sale)

      const sale = sortedSales.find((sale) => sale.from <= quantity)

      updatedPrice = sale ? sale.price : updatedPrice
    }

    const updatedCart = storagedCart.map((cartItem) =>
      cartItem.id === item.id
        ? { ...cartItem, quantity, price: updatedPrice }
        : cartItem
    )

    setStoragedCart(updatedCart)
  }

  const handleFilter = (filter: string) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(
        selectedFilters.filter((selected) => selected !== filter)
      )
    } else {
      setSelectedFilters([...selectedFilters, filter])
    }

    setCurrentPage(1)
  }

  const {
    data: products,
    isLoading,
    isError,
  } = useQuery<IListProducts>({
    queryKey: ["products", slug, search, selectedFilters, currentPage],
    queryFn: () =>
      ecommerceService.listProducts.fn({
        slug,
        currentPage,
        name: search,
        isRelease: selectedFilters.includes("is_release"),
        isSale: selectedFilters.includes("is_sale"),
      }),
  })

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: "onChange",
  })

  const { mutate: createOrder, isPending: isCreatingOrderPending } =
    useMutation({
      mutationFn: ecommerceService.createOrder.fn,
      mutationKey: [ecommerceService.createOrder.key],
      onSuccess: () => {
        setIsFinishingOrder(false)
        setStoragedCart([])
        setDrawerIsOpen(false)
        reset()
      },
      onError: () => {
        setIsFinishingOrder(false)
        toast.error("Ocorreu um erro ao realizar o pedido")
      },
    })

  const handleOrder = (data: {
    name: string
    email: string
    cnpj: string
    payment_method: string
  }) => {
    setIsFinishingOrder(true)
    createOrder({
      slug: slug as string,
      name: data.name,
      email: data.email,
      cnpj: data.cnpj,
      payment_method: data.payment_method,
      products: storagedCart.map((item) => ({
        product_id: item.id,
        quantity: item.quantity,
      })),
    })
  }

  if (isError) {
    return <div>Ocorreu um erro ao carregar os produtos</div>
  }

  return (
    <>
      <div className="w-screen lg:min-h-screen mx-auto p-4 overflow-y-auto bg-white">
        <div className="max-w-[1440px] h-full mx-auto flex flex-col">
          <div className="flex justify-between mb-5">
            <div className="h-10 flex items-center">
              <FullLogo />
            </div>
            <label
              className="relative flex items-center justify-center rounded-full text-primary-300 w-12 h-12 cursor-pointer"
              onClick={() => setDrawerIsOpen(true)}
            >
              <ShoppingBag size={35} weight="fill" />
              <span className="flex items-center justify-center absolute bottom-6 left-6 border-2 border-primary-300 bg-white w-6 h-6 rounded-full font-bold">
                {storagedCart.length}
              </span>
            </label>
          </div>

          {/* Barra de pesquisa */}
          <SearchBar onSearch={setSearch} />

          {/* Filtros */}
          <div className="my-5">
            <FilterBar
              selectedFilters={selectedFilters}
              handleFilter={handleFilter}
            />
          </div>

          {isLoading ? (
            <div className="h-[calc(100vh-250px)] flex items-center justify-center">
              <Loading iconLoading="ShoppingBag" size={128} />
            </div>
          ) : products?.data.length === 0 ? (
            <div className="h-[calc(100vh-250px)] flex items-center justify-center">
              <span className="text-xl text-gray-500">
                Desculpe, nenhum produto encontrado. 😢
              </span>
            </div>
          ) : (
            <>
              <ProductGrid
                products={products?.data || []}
                storagedCart={storagedCart}
                handleAddCart={handleAddCart}
                handleRemoveCart={handleRemoveCart}
                handleOpenDrawer={() => setDrawerIsOpen(true)}
              />
              <div className="flex items-center self-end my-6">
                <span className="mr-5">
                  Total de produtos: <strong>{products?.total}</strong>
                </span>
                <Pagination
                  totalItems={products?.total || 0}
                  currentPage={products?.current_page || 1}
                  itemsPerPage={15}
                  maxVisiblePages={products?.pages}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <CartDrawer
        total={total}
        errors={errors}
        isValid={isValid}
        register={register}
        isPending={isCreatingOrderPending}
        storagedCart={storagedCart}
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
        handleRemoveCart={handleRemoveCart}
        isFinishingOrder={isFinishingOrder}
        handleClearCart={() => {
          setStoragedCart([])
          setDrawerIsOpen(false)
        }}
        handleSubmit={handleSubmit(handleOrder)}
        setIsFinishingOrder={setIsFinishingOrder}
        handleUpdateQuantity={handleUpdateQuantity}
      />
    </>
  )
}

export default Ecommerce
