import clsx from "clsx"
import Input from "./Input"
import React from "react"
import Tooltip from "@components/Tooltip"
import { IInpuWithLabel } from "@interfaces/Components/Input"
import { InputSizes } from "@constants/Components/Input"
import { Info } from "phosphor-react"

const InputWithLabel: React.FC<IInpuWithLabel> = ({
  label,
  info,
  className,
  ...rest
}) => {
  return (
    <div
      className={clsx(`flex flex-col justify-start items-start ${className}`, {
        [InputSizes[rest.size || "small"]]: rest.size,
      })}
    >
      <div className="w-full flex justify-start items-center mb-1">
        <label className="w-fit h-5 text-wrap text-sm text-black-800 font-medium text-left text-gray-600">
          {label}
        </label>
        {info && (
          <Tooltip id={rest.register ? rest.register.name : ""} text={info}>
            <Info size={16} className="ml-1" />
          </Tooltip>
        )}
      </div>

      <Input {...rest} />
    </div>
  )
}

export default InputWithLabel
