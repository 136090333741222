import makeAnimated from "react-select/animated"
import React from "react"
import ReactSelect from "react-select"
import { ISelect } from "@interfaces/Components/Input"
import { reactSelectStyles } from "@constants/Components/ReactSelect"

const animatedComponents = makeAnimated()

const Select: React.FC<ISelect> = ({
  label,
  options,
  placeholder,
  className,
  isDisabled,
  selectedValue,
  register,
  onSelectOption,
  position,
}) => {
  const optionSelected = options?.find(
    (option) => option?.value === selectedValue
  )

  return (
    <div
      className={`flex flex-col justify-start items-start min-w-44 ${className}`}
    >
      {label && (
        <label className="w-full h-5 text-wrap text-sm mb-1 text-left text-gray-800 font-medium">
          {label}
        </label>
      )}
      <ReactSelect
        className={`min-w-44 ${className}`}
        styles={reactSelectStyles}
        placeholder={placeholder || "Selecione uma opção"}
        closeMenuOnSelect={true}
        menuPlacement={position}
        components={animatedComponents}
        options={options}
        value={register?.value || optionSelected}
        {...register}
        onChange={(selected: any) => {
          onSelectOption?.(selected)
          register?.onChange({
            target: { value: selected?.value, name: register?.name },
          })
        }}
        defaultValue={options?.[0]}
        isDisabled={isDisabled}
      />
    </div>
  )
}

export default Select
