import Card from "@components/Card"
import clsx from "clsx"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import React from "react"
import { IInformations } from "@interfaces/Pages/Products"
import { useFormContext } from "react-hook-form"

const Informations: React.FC<IInformations> = ({
  fields,
  hasActionInProgress,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <Card className="w-full ">
      <p className="text-lg font-semibold text-primary-500 mb-4">Informações</p>
      <div className="w-full grid grid-cols-2 gap-4">
        {fields.map((field, index) => (
          <InputWithLabel
            key={field.key}
            label={field.label}
            placeholder={field.label}
            size="full"
            info={field.info}
            register={register(field.key)}
            error={errors[field.key]?.message as string}
            masks={field.masks as any}
            className={clsx({
              "col-span-2":
                index === fields.length - 1 && fields.length % 2 !== 0,
            })}
            isDisabled={hasActionInProgress}
          />
        ))}
      </div>
    </Card>
  )
}

export default Informations
