import * as PhosphorIcons from "phosphor-react"
import React from "react"
import Skeleton from "react-loading-skeleton"

// import { Container } from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOrderDetailCard {
  icon?: keyof typeof PhosphorIcons
  title: string
  info?: string
}

const OrderDetailCard: React.FC<IOrderDetailCard> = ({ icon, title, info }) => {
  const IconComponent: any = PhosphorIcons[icon as keyof typeof PhosphorIcons]

  if (!info) return <Skeleton height={100} />

  return (
    <div className="w-full flex flex-col items-start gap-2 bg-white p-4 rounded-lg shadow-lg text-primary-600">
      <div className="flex justify-center items-center gap-2">
        <IconComponent size={24} weight="bold" />
        <p className="text-lg font-medium">{title}</p>
      </div>
      <p className="text-base">{info}</p>
    </div>
  )
}

export default OrderDetailCard
