import { ICartItem } from "@interfaces/Components/Ecommerce"
import CartItem from "./CartItem"
import clsx from "clsx"
import { formatNumberToMoney } from "@utils/money"
import { ArrowCircleLeft, TrashSimple } from "phosphor-react"
import ReactDrawer from "react-modern-drawer"
import "react-modern-drawer/dist/index.css"
import FinishOrder from "./FinishOrder"

const CartDrawer: React.FC<{
  drawerIsOpen: boolean
  storagedCart: ICartItem[]
  total: number
  register: any
  errors: any
  isValid: boolean
  isPending: boolean
  isFinishingOrder: boolean
  setDrawerIsOpen: (value: boolean) => void
  handleUpdateQuantity: (item: ICartItem, quantity: number) => void
  setIsFinishingOrder: (value: boolean) => void
  handleRemoveCart: (item: ICartItem) => void
  handleSubmit: () => void
  handleClearCart: () => void
}> = ({
  total,
  errors,
  isValid,
  register,
  drawerIsOpen,
  storagedCart,
  isFinishingOrder,
  isPending,
  handleSubmit,
  handleClearCart,
  setDrawerIsOpen,
  handleRemoveCart,
  handleUpdateQuantity,
  setIsFinishingOrder,
}) => {
  return (
    <>
      <ReactDrawer
        open={drawerIsOpen}
        onClose={() => {
          setDrawerIsOpen(false)
          setIsFinishingOrder(false)
        }}
        direction="right"
        className="react-modern-drawer"
      >
        <div
          className={clsx(
            "w-full h-full flex flex-col transition-all duration-300 ease-in-out"
          )}
        >
          <div className="w-full h-[12%] flex flex-col justify-between items-center p-2">
            <p className="text-xl font-bold mb-2 text-gray-500">Carrinho de Compras</p>
            <div className="w-full flex justify-between gap-2">
              <button
                className="text-primary-500 p-2 border border-zinc-400 rounded-md flex text-base pop"
                onClick={() => {
                  setDrawerIsOpen(false)
                  setIsFinishingOrder(false)
                }}
              >
                Voltar
                <ArrowCircleLeft
                  size={24}
                  className="text-primary-500"
                  weight="fill"
                />
              </button>
              <button
                className="text-primary-500 p-2 border border-zinc-400 rounded-md flex text-base disabled:opacity-50 pop"
                onClick={handleClearCart}
                disabled={storagedCart.length === 0}
              >
                Esvaziar
                <TrashSimple
                  size={24}
                  className="text-primary-500"
                  weight="fill"
                />
              </button>
            </div>
          </div>
          <div className="w-full h-[1px] bg-gray-300 mt-2" />
          <div
            className={clsx(
              "p-4 flex flex-col gap-4 overflow-y-auto transition-all duration-300 ease-in-out",
              isFinishingOrder ? "h-[38%] lg:h-[47%]" : "h-[70%] lg:h-[80%]"
            )}
          >
            {storagedCart.length === 0 ? (
              <p className="text-center">Seu carrinho está vazio.</p>
            ) : (
              storagedCart.map((item) => (
                <CartItem
                  key={item.id}
                  item={item}
                  handleUpdateQuantity={handleUpdateQuantity}
                  handleRemoveCart={handleRemoveCart}
                />
              ))
            )}
          </div>
          <div className="w-full h-[1px] bg-gray-300" />
          <div className={clsx("p-4 h-[20%] lg:h-fit")}>
            <div className="flex flex-col items-center text-lg">
              <div className="w-full flex justify-between mb-6">
                <span className="font-semibold text-xl">Total</span>
                <span>{formatNumberToMoney(total)}</span>
              </div>
              <button
                disabled={storagedCart.length === 0}
                className="w-full btn bg-primary-500 px-4 py-2 text-white rounded-md text-lg self-center font-normal"
                onClick={() => setIsFinishingOrder(true)}
              >
                Finalizar pedido
              </button>
            </div>
          </div>
        </div>
      </ReactDrawer>
      <ReactDrawer
        open={isFinishingOrder}
        onClose={() => setIsFinishingOrder(false)}
        direction="bottom"
        className="react-modern-drawer-bottom"
        enableOverlay={false}
      >
        <FinishOrder
          total={total}
          register={register}
          errors={errors}
          isValid={isValid}
          isPending={isPending}
          setIsFinishingOrder={setIsFinishingOrder}
          isFinishingOrder={isFinishingOrder}
          onFinishingOrder={handleSubmit}
        />
      </ReactDrawer>
    </>
  )
}

export default CartDrawer
