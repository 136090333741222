import { PopUpContentStyle } from "@constants/Components"
import { Eye } from "phosphor-react"
import React from "react"
import Popup from "reactjs-popup"

// import { Container } from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IHeadersFilter {
  headers: {
    key: string
    title: string
  }[]
  visibleColumns: string[]
  setVisibleColumns: (columns: string[]) => void
}

// eslint-disable-next-line no-empty-pattern
const HeadersFilter: React.FC<IHeadersFilter> = ({
  headers,
  setVisibleColumns,
  visibleColumns,
}) => {
  return (
    <Popup
      trigger={() => (
        <div className="w-fit h-full flex justify-center items-center text-primary-500 border border-primary-500 p-1 px-4 rounded-md cursor-pointer relative">
          <Eye size={25} className="mr-2" weight="bold" />
          <p>Colunas visíveis</p>
        </div>
      )}
      position="left center"
      on={["hover"]}
      contentStyle={PopUpContentStyle}
    >
      <div className="p-2 grid grid-cols-2">
        {headers.map((header) => (
          <div key={header.key} className="flex items-center gap-2 m-2">
            <input
              type="checkbox"
              id={header.key}
              name={header.key}
              checked={visibleColumns.includes(header.key)}
              onChange={(e) => {
                if (e.target.checked) {
                  setVisibleColumns([...visibleColumns, header.key])
                } else {
                  setVisibleColumns(
                    visibleColumns.filter((column) => column !== header.key)
                  )
                }
              }}
              className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            />
            <label htmlFor={header.key} className="text-sm text-gray-700">
              {header.title}
            </label>
          </div>
        ))}
      </div>
    </Popup>
  )
}

export default HeadersFilter
