export const basicValidation = (value?: string, limit = 9999) => {
  if (!value) return ""

  if (value.length >= limit) return value.replace(/\D/g, "").slice(0, limit)

  return value.replace(/\D/g, "")
}

const Masks = {
  cpf: (value: string) => {
    const cleanValue = basicValidation(value, 11)

    return cleanValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
  },
  cnpj: (value: string) => {
    const cleanValue = basicValidation(value, 14)

    return cleanValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    )
  },
  phone: (value: string) => {
    const cleanValue = basicValidation(value, 11)

    return cleanValue.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
  },
  money: (value: string | number) => {
    const cleanValue = basicValidation(String(value), 6)

    return Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(Number(cleanValue) / 100)
  },
  onlyNumbers: (value: string) => basicValidation(value),
  onlyLetters: (value: string) => value.replace(/[^a-zA-ZÀ-ú\s]/g, ""),
  EAN: (value: string) => {
    return basicValidation(value, 13)
  },
} as const

export { Masks }
