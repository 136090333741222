import { IListClients, IListOrders, IListProducts } from "@interfaces/Services"
import { formatNumberToMoney } from "./money"
import StatusTag from "@components/StatusTag"
import { Tooltip } from "react-tooltip"
import TableActions from "@components/Table/TableActions"
import { Masks } from "@constants/Masks"
import { OrderStatus } from "@constants/index"
import TableActionItem from "@components/Table/TableActionItem"
import ImageWithView from "@components/ImageWithView"

export const formatOrderDetailsData = (data: any) => {
  if (!data) return []

  return data.map((order: any) => ({
    ...order,
    name: {
      value: order?.product?.name,
      component: (
        <div className="flex items-center gap-2">
          {order?.product?.name?.length > 30 && (
            <Tooltip id={order?.id} content={order?.product?.name} />
          )}
          <img
            src={order?.product?.drug?.image_url}
            alt={order?.product?.name}
            className="w-8 h-8 rounded-full"
          />
          <span data-tooltip-id={order?.id} className="truncate">
            {order?.product?.name}
          </span>
        </div>
      ),
    },
    quantity: order?.quantity,
    price: formatNumberToMoney(order?.price),
    total: formatNumberToMoney(order?.price * order?.quantity),
  }))
}

export const formatProductsData = (
  data?: IListProducts["data"],
  setDeleteProductId?: (value: string) => void,
  setEditProductModalIsOpen?: (value: string) => void
) => {
  if (!data) return []

  return data.map((product) => ({
    ...product,
    name: {
      value: product.name,
      component: (
        <div className="flex items-center gap-2">
          {product.name.length > 30 && (
            <Tooltip id={product.id} content={product.name} />
          )}
          <ImageWithView imagesUrl={product.image_urls} alt={product.name} />

          <span data-tooltip-id={product.id} className="truncate">
            {product.name}
          </span>
        </div>
      ),
    },
    link: {
      value: "",
      component: (
        <a
          href={
            product?.custom_fields?.find((field) => field.key === "product_url")
              ?.value
          }
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          Acessar site
        </a>
      ),
    },
    price: formatNumberToMoney(product.price),
    sale_price:
      product?.sale_price > 0
        ? formatNumberToMoney(product.sale_price)
        : "Sem promoção",
    is_available: {
      value: product.is_available ? "Sim" : "Não",
      component: (
        <StatusTag
          text={product.is_available ? "Sim" : "Não"}
          type={product.is_available ? "success" : "danger"}
        />
      ),
    },
    is_release: {
      value: product.is_release ? "Sim" : "Não",
      component: (
        <StatusTag
          text={product.is_release ? "Sim" : "Não"}
          type={product.is_release ? "success" : "danger"}
        />
      ),
    },
    actions: {
      value: "",
      component: (
        <TableActions
          id={product.id}
          onEdit={setEditProductModalIsOpen}
          onDelete={setDeleteProductId}
        />
      ),
    },
  }))
}

export const formatOrdersData = (
  data: IListOrders["data"],
  onDelete: (id: string) => void,
  handleViewDetails: (id: string) => void,
  handleApproveOrder: (id: string) => void,
  handleDisapproveOrder: (id: string) => void
) => {
  if (!data) return []

  return data.map((order: any) => ({
    ...order,
    cnpj: Masks.cnpj(order.cnpj),
    total: formatNumberToMoney(order.total),
    createdAt: new Date(order.created_at).toLocaleString(),
    status: {
      value: order.status,
      component: (
        <StatusTag
          text={OrderStatus?.[order?.status]?.value}
          type={OrderStatus?.[order?.status]?.type as any}
        />
      ),
    },
    actions: {
      value: "",
      component: (
        <TableActions id={order.id} onDelete={onDelete}>
          <TableActionItem
            handleClick={() => handleViewDetails(order.id)}
            text="Detalhes"
            icon="Eye"
          />
          {order.status !== OrderStatus.approved.key && (
            <TableActionItem
              handleClick={() => handleApproveOrder(order.id)}
              text="Aprovar"
              icon="Check"
            />
          )}
          {order.status !== OrderStatus.canceled.key && (
            <TableActionItem
              handleClick={() => handleDisapproveOrder(order.id)}
              text="Cancelar"
              icon="X"
            />
          )}
        </TableActions>
      ),
    },
  })) as any
}

export const formatClientsData = (
  data: IListClients["data"],
  onDelete?: (id: string) => void,
  onEdit?: (id: string) => void
) => {
  if (!data) return []

  return data.map((client: any) => ({
    ...client,
    status: {
      value: client.is_active ? "Ativo" : "",
      component: (
        <StatusTag
          text={client.is_active ? "Ativo" : "Inativo"}
          type={client.is_active ? "success" : "danger"}
        />
      ),
    },
    actions: {
      value: "",
      component: (
        <TableActions id={client.id} onEdit={onEdit} onDelete={onDelete} />
      ),
    },
  })) as any
}
