import InputWithLabel from "@components/Inputs/InputWithLabel"
import Select from "@components/Inputs/Select"
import { formatNumberToMoney } from "@utils/money"
import clsx from "clsx"
import { Spinner, X } from "phosphor-react"
import React from "react"

// import { Container } from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFinishOrder {
  isFinishingOrder: boolean
  errors: any
  register: any
  total: number
  isValid: boolean
  isPending: boolean
  setIsFinishingOrder: (value: boolean) => void
  onFinishingOrder: () => void
}

// eslint-disable-next-line no-empty-pattern
const FinishOrder: React.FC<IFinishOrder> = ({
  isFinishingOrder,
  errors,
  register,
  total,
  isValid,
  isPending,
  setIsFinishingOrder,
  onFinishingOrder,
}) => {
  return (
    <div
      className={clsx(
        "absolute w-full left-0 p-6 bg-[#f3f4f9] border-t border-gray-500 transition-all duration-300 ease-in-out mt-auto",
        isFinishingOrder ? "bottom-0" : "bottom-[-600px]"
      )}
    >
      <div className="w-full flex justify-end">
        <X
          size={28}
          weight="bold"
          className="cursor-pointer"
          onClick={() => setIsFinishingOrder(false)}
        />
      </div>
      <form
        className="flex flex-col items-start justify-center"
        onSubmit={onFinishingOrder}
      >
        <InputWithLabel
          label="Nome"
          placeholder="Nome"
          size="full"
          register={register("name")}
          error={errors.name?.message}
        />

        <InputWithLabel
          label="Email"
          placeholder="Email"
          size="full"
          register={register("email")}
          error={errors.email?.message}
        />

        <div className="w-full flex items-start justify-start gap-2">
          <InputWithLabel
            label="CNPJ"
            placeholder="CNPJ"
            size="full"
            masks="cnpj"
            register={register("cnpj")}
            error={errors.cnpj?.message}
          />

          <Select
            label="Método de pagamento"
            register={register("payment_method")}
            className="w-full"
            position="top"
            options={[
              {
                value: "",
                label: "Selecione um método",
              },
              {
                value: "7_days",
                label: "Cartão de crédito",
              },
              {
                value: "42_days",
                label: "Boleto",
              },
            ]}
            error={errors.payment_method?.message}
          />
        </div>

        <div className="w-full flex items-center justify-between gap-4 mt-3">
          <span className="w-full text-lg text-left">
            Total: <strong>{formatNumberToMoney(total)}</strong>
          </span>

          <button
            disabled={!isValid || isPending}
            className="btn rounded-lg bg-primary-300 text-white self-center font-medium text-base px-8 "
          >
            Realizar pedido
            {isPending && (
              <span className="animate-spin">
                <Spinner size={24} />
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default FinishOrder
