import Card from "@components/Card"
import ConfirmActionModal from "@components/Modals/ConfirmAction"
import HeadersFilter from "@components/Table/HeadersFilter"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import Pagination from "@components/Pagination"
import React, { useEffect, useState } from "react"
import Select from "@components/Inputs/Select"
import Table from "@components/Table/Table"
import TableFilters from "@components/Table/TableFilters"
import UnderlineTitle from "@components/UnderlineTitle"
import useStore from "@store"
import { formatProductsData } from "@utils/formatters"
import { privateRoutes } from "@constants/Routes"
import { productsService } from "@services/Products"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import {
  headersBySegment,
  tableVisibleColumnsBySegment,
} from "@constants/Products"

const Products: React.FC = ({}) => {
  const navigate = useNavigate()
  const { userDetail } = useStore()

  const [pageFilters, setPageFilters] = useState({
    actualPage: 1,
    itemsPerPage: 15,
  })

  const userSegment = userDetail?.segment_key || ""

  const [filters, setFilters] = useState({
    name: "",
    type: "",
    isAvailable: "",
    isRelease: "",
  })

  const [lastQuantityOfTotalItems, setLastQuantityOfTotalItems] = useState(0)
  const [deleteProductId, setDeleteProductId] = useState("")

  const [visibleColumns, setVisibleColumns] = useState(
    tableVisibleColumnsBySegment.drugs
  )

  const {
    data: listProductsData,
    isFetching: isListProductsPending,
    refetch: refetchListProducts,
  } = useQuery({
    queryKey: [productsService.listProducts.key, filters, pageFilters],
    queryFn: () =>
      productsService.listProducts.fn({
        page: pageFilters.actualPage,
        ...filters,
      }),
  })

  const { mutate: deleteProduct, isPending: isDeletePending } = useMutation({
    mutationFn: productsService.deleteProduct.fn,
    onSuccess: () => {
      toast.success("Produto deletado com sucesso!")
      setPageFilters({ ...pageFilters, actualPage: 1 })
      setDeleteProductId("")
      refetchListProducts()
    },
    onError: (error) => {
      toast.error("Erro ao deletar produto: " + error.message)
    },
  })

  // const { mutate: updateProduct, isPending: isUpdatePending } = useMutation({
  //   mutationFn: productsService.updateProduct.fn,
  //   onSuccess: () => {
  //     toast.success("Produto atualizado com sucesso!")
  //     refetchListProducts()
  //   },
  //   onError: (error) => {
  //     toast.error("Erro ao atualizar produto: " + error.message)
  //   },
  // })

  useEffect(() => {
    if (
      listProductsData?.total &&
      listProductsData?.total !== lastQuantityOfTotalItems
    ) {
      setLastQuantityOfTotalItems(listProductsData?.total)
    }
  }, [listProductsData])

  const handleChangePage = (page: number) => {
    setPageFilters({ ...pageFilters, actualPage: page })
  }

  const handleChangeFilter = (filtersToChange: any) => {
    setFilters({ ...filters, ...filtersToChange })
  }

  const handleChangeName = (text: string) => {
    handleChangeFilter({
      name: text,
    })
  }

  const availableOptions = [
    { value: "", label: "Todos" },
    { value: "true", label: "Sim" },
    { value: "false", label: "Não" },
  ]

  const releaseOptions = availableOptions

  const typeOptions = [
    { value: "", label: "Todos" },
    { value: "generic", label: "Genérico" },
    { value: "owned", label: "Referência" },
  ]

  const allFiltersAreEmpty =
    filters.name === "" &&
    filters.type === "" &&
    filters.isAvailable === "" &&
    filters.isRelease === ""

  const dontHasProducts =
    allFiltersAreEmpty &&
    (!listProductsData?.data || listProductsData?.data.length === 0)

  const hasProdutToDelete = deleteProductId !== ""

  const customEmptyMessage = dontHasProducts ? (
    <div className="text-center flex flex-col gap-2">
      <p>Nenhum produto encontrado.</p>
      <p>Você pode adicionar um novo produto clicando no botão abaixo.</p>
    </div>
  ) : undefined

  const handleSelectAvailable = (selected: {
    value: string
    label: string
  }) => {
    handleChangeFilter({
      isAvailable: selected.value,
    })
  }

  const handleSelectRelease = (selected: { value: string; label: string }) => {
    handleChangeFilter({
      isRelease: selected.value,
    })
  }

  const handleSelectType = (selected: { value: string; label: string }) => {
    handleChangeFilter({
      type: selected.value,
    })
  }

  const howManyFiltersAreActive =
    Object.values(filters).filter((filter) => filter !== "").length || undefined

  const tableListFilteredBySegment = headersBySegment?.[userSegment]?.filter?.(
    (header) => visibleColumns?.includes(header.key)
  )

  const handleEditProduct = (productId: string) => {
    navigate(`${productId}`)
  }

  return (
    <>
      {hasProdutToDelete && (
        <ConfirmActionModal
          isOpen={hasProdutToDelete}
          title="Deletar produto"
          message="Tem certeza que deseja deletar esse produto?"
          onConfirm={() => {
            deleteProduct(deleteProductId)
          }}
          onCancel={() => {
            setDeleteProductId("")
          }}
          icon="Package"
          isPending={isDeletePending}
        />
      )}
      <div className="flex justify-between items-center w-full">
        <UnderlineTitle />
      </div>
      <Card>
        <div className="flex justify-end w-full h-fit mb-8 gap-4">
          {!dontHasProducts && (
            <TableFilters
              filtersQuantity={howManyFiltersAreActive}
              clearFilters={() => {
                setFilters({
                  name: "",
                  type: "",
                  isAvailable: "",
                  isRelease: "",
                })
              }}
            >
              <div className="grid grid-cols-2 gap-4">
                <InputWithLabel
                  label="Nome"
                  rightIcon="MagnifyingGlass"
                  placeholder="Pesquisar por nome"
                  createEspaceToLabel={false}
                  onType={handleChangeName}
                  isDisabled={dontHasProducts}
                  value={filters.name}
                />
                <Select
                  label="Tipo"
                  placeholder="Tipo"
                  options={typeOptions}
                  selectedValue={filters.type}
                  onSelectOption={handleSelectType}
                  isDisabled={dontHasProducts}
                />
                <Select
                  label="Disponível"
                  placeholder="Disponível"
                  options={availableOptions}
                  selectedValue={filters.isAvailable}
                  onSelectOption={handleSelectAvailable}
                  isDisabled={dontHasProducts}
                />
                <Select
                  label="Lançamento"
                  placeholder="Lançamento"
                  options={releaseOptions}
                  selectedValue={filters.isRelease}
                  onSelectOption={handleSelectRelease}
                  isDisabled={dontHasProducts}
                />
              </div>
            </TableFilters>
          )}
          {!dontHasProducts && (
            <HeadersFilter
              headers={headersBySegment?.[userSegment] || []}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
            />
          )}
          <SimpleButton
            label="Adicionar produto"
            icon="Plus"
            onClick={() => {
              navigate(privateRoutes.newProduct)
            }}
          />
        </div>
        <Table
          headers={tableListFilteredBySegment}
          data={formatProductsData(
            listProductsData?.data,
            setDeleteProductId,
            handleEditProduct
          )}
          customEmptyMessage={customEmptyMessage}
          isPending={isListProductsPending}
          emptyIcon={dontHasProducts ? "Package" : "SmileySad"}
          iconLoading="Package"
        />
        {!dontHasProducts &&
          (listProductsData?.total || lastQuantityOfTotalItems) && (
            <div className="w-full flex justify-end items-center mt-auto">
              <Pagination
                currentPage={pageFilters.actualPage}
                itemsPerPage={pageFilters.itemsPerPage}
                totalItems={
                 900
                }
                onPageChange={handleChangePage}
              />
            </div>
          )}
      </Card>
    </>
  )
}

export default Products
