import { SidebarOptions } from "@interfaces/Constants/Routes"
import { logout } from "@services/User"

export const publicRoutes = {
  landing: "/",
  splashScreen: "/splash-screen",
  forgotPassword: "/forgot-password",
  signIn: "/sign-in",
  signUp: "/sign-up",
  resetPassword: "/reset-password",
  products: "/produtos",
}

export const privateRoutes = {
  dashboard: "/dashboard",
  products: "/meus-produtos",
  newProduct: "/meus-produtos/novo",
  editProduct: "/meus-produtos/:productId",
  orders: "/pedidos",
  orderDetails: "/pedidos/:orderId",
  settings: "/configuracoes",
  profile: "/perfil",
  clients: "/clientes",
  admin: "/admin",
}

export const sidebarMenu: (userDetails?: any) => SidebarOptions[] = (
  userDetails
) => [
  {
    title: "Dashboard",
    icon: "SquaresFour",
    route: privateRoutes.dashboard,
  },
  {
    title: "Clientes",
    icon: "Users",
    route: privateRoutes.clients,
  },
  {
    title: "Pedidos",
    icon: "ShoppingCart",
    route: privateRoutes.orders,
  },
  {
    title: "Meus Produtos",
    icon: "Package",
    route: privateRoutes.products,
    childRoute: privateRoutes.newProduct,
  },
  {
    title: "Administração",
    icon: "ShieldStar",
    route: privateRoutes.admin,
    flagPermission: "admin",
  },
  {
    title: "Configurações",
    icon: "Gear",
    childRoute: `${privateRoutes.settings}${privateRoutes.profile}`,

    steps: [
      {
        title: "Perfil",
        icon: "User",
        route: `${privateRoutes.settings}${privateRoutes.profile}`,
      },
      {
        title: "Acessar Loja",
        icon: "ShoppingBag",
        route: `/produtos?slug=${userDetails?.slug}`,
        navigateBlank: true,
      },
    ],
  },
  {
    title: "Sair",
    icon: "SignOut",
    onClick: () => {
      logout.fn()
    },
  },
]
