import clsx from "clsx"

interface FilterBarProps {
  selectedFilters: string[]
  handleFilter: (filter: string) => void
}

const FilterBar: React.FC<FilterBarProps> = ({
  selectedFilters,
  handleFilter,
}) => {
  return (
    <div className="flex items-center gap-2">
      <span className="font-semibold text-base mr-2">Filtrar por:</span>
      <span
        onClick={() => handleFilter("is_release")}
        className={clsx(
          "rounded-full py-2 px-6 flex items-center cursor-pointer transition-all duration-300",
          !selectedFilters.includes("is_release") &&
            "bg-gray-200 text-gray-500",
          selectedFilters.includes("is_release") && "bg-primary-300 text-white"
        )}
      >
        Lançamento
      </span>
      <span
        onClick={() => handleFilter("is_sale")}
        className={clsx(
          "rounded-full py-2 px-6 flex items-center cursor-pointer transition-all duration-300",
          !selectedFilters.includes("is_sale") && "bg-gray-200 text-gray-500",
          selectedFilters.includes("is_sale") && "bg-primary-300 text-white"
        )}
      >
        Promoção
      </span>
    </div>
  )
}

export default FilterBar
