import UnderlineTitle from "@components/UnderlineTitle"
import useStore from "@store"
import React from "react"

const Dashboard: React.FC = ({}) => {
  const { userDetail } = useStore()

  return (
    <>
      <div className="flex justify-between items-center w-full mb-6">
        <UnderlineTitle />
      </div>
      <div>
        <h1>Seja bem vindo,</h1>
        <h2>{userDetail?.full_name}</h2>
      </div>
    </>
  )
}

export default Dashboard
