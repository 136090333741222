import { IProductResponse } from "@interfaces/Services"
import { Card } from "@components/Ecommerce/Card"
import { ICartItem } from "@interfaces/Components/Ecommerce"

interface ProductGridProps {
  products: IProductResponse[]
  storagedCart: ICartItem[]
  handleOpenDrawer: () => void
  handleAddCart: (item: IProductResponse) => void
  handleRemoveCart: (item: ICartItem) => void
}

const ProductGrid: React.FC<ProductGridProps> = ({
  products,
  storagedCart,
  handleOpenDrawer,
  handleAddCart,
  handleRemoveCart,
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {products.map((item) => (
        <Card
          key={item.id}
          product={item}
          isOnCart={storagedCart.some((cartItem) => cartItem.id === item.id)}
          countInCart={
            storagedCart.find((cartItem) => cartItem.id === item.id)
              ?.quantity || 0
          }
          onAddCart={handleAddCart}
          onRemoveCart={handleRemoveCart}
          handleOpenDrawer={handleOpenDrawer}
        />
      ))}
    </div>
  )
}

export default ProductGrid
