import Card from "@components/Card"
import clsx from "clsx"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import React from "react"
import { Plus, X } from "phosphor-react"
import { useFieldArray, useFormContext } from "react-hook-form"

interface ISales {
  hasActionInProgress?: boolean
}

const Sales: React.FC<ISales> = ({ hasActionInProgress }) => {
  const { register, control } = useFormContext()

  const {
    fields: salesFields,
    append: appendSales,
    remove: removeSales,
  } = useFieldArray({
    name: "sales",
    control,
  })

  return (
    <Card className="w-full min-h-20 flex flex-col justify-between">
      <div className="w-full flex flex-col">
        <div className="w-full flex justify-center items-end mb-4">
          <div className="w-full flex flex-col">
            <p className="text-lg font-semibold text-neutral-700">Promoções</p>
            <p className="text-base text-neutral-500">
              Adicione promoções para esse produto
            </p>
          </div>
          <Plus
            size={32}
            weight="fill"
            className={clsx("text-primary-500", {
              "opacity-30 cursor-not-allowed":
                salesFields?.length >= 3 || hasActionInProgress,
              "cursor-pointer": salesFields?.length < 3 && !hasActionInProgress,
            })}
            onClick={() => {
              if (salesFields?.length >= 3 || hasActionInProgress) return

              salesFields?.length < 3 &&
                appendSales({ from: "", price: "", id: Math.random() })
            }}
          />
        </div>
        <div className="w-full min-h-[120px] flex flex-col max-h-96 overflow-y-auto overflow-x-hidden p-1">
          {salesFields.length > 0 ? (
            salesFields?.map((sale, index) => (
              <div className="w-full flex items-center gap-x-4" key={sale.id}>
                <InputWithLabel
                  label="A partir de (quantidade)"
                  placeholder="A partir de (quantidade)"
                  size="full"
                  register={register(`sales.${index}.from`)}
                  masks="onlyNumbers"
                  isDisabled={hasActionInProgress}
                />
                <InputWithLabel
                  label="Valor promocional"
                  placeholder="Valor promocional"
                  size="full"
                  register={register(`sales.${index}.price`)}
                  masks="money"
                  isDisabled={hasActionInProgress}
                />
                <X
                  size={64}
                  weight="bold"
                  className={clsx(
                    "text-red-300",
                    hasActionInProgress && "opacity-30 cursor-not-allowed",
                    !hasActionInProgress && "hover:text-red-500 cursor-pointer"
                  )}
                  onClick={() => {
                    if (hasActionInProgress) return
                    removeSales(index)
                  }}
                />
              </div>
            ))
          ) : (
            <div className="flex h-full justify-center items-center text-neutral-600">
              <p>
                Nenhuma promoção adicionada. Clique no botão de adicionar para
                incluir promoções.
              </p>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default Sales
