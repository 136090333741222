import React from "react"
import { Funnel } from "phosphor-react"
import Popup from "reactjs-popup"
import { PopUpContentStyle } from "@constants/Components"
import { PopupPosition } from "reactjs-popup/dist/types"
import { SimpleButton } from "../Buttons/SimpleButton"

interface ITableFilters {
  children?: React.ReactNode
  position?: PopupPosition | PopupPosition[]
  filtersQuantity?: number
  clearFilters?: () => void
}

const TableFilters: React.FC<ITableFilters> = ({
  children,
  position = "left top",
  filtersQuantity,
  clearFilters,
}) => {
  return (
    <div className="w-fit flex justify-end items-center">
      <Popup
        trigger={() => (
          <div className="w-fit h-full flex justify-center items-center text-primary-500 border border-primary-500 p-1 px-4 rounded-md cursor-pointer relative">
            {filtersQuantity && (
              <div className="absolute -top-3 -right-3 bg-primary-500 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p>{filtersQuantity}</p>
              </div>
            )}
            <Funnel size={25} className="mr-2" weight="bold" />
            <p>Filtros</p>
          </div>
        )}
        position={position}
        on={["hover"]}
        contentStyle={PopUpContentStyle}
      >
        {children}
        {clearFilters && (
          <SimpleButton
            label="Limpar filtros"
            onClick={clearFilters}
            size="full"
            disabled={!filtersQuantity}
          />
        )}
      </Popup>
    </div>
  )
}

export default TableFilters
