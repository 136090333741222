import { toast } from "sonner"

export const generateErrorMessage = (typeMessage: string, error: any) => {
  toast.error(
    typeMessage + error.response?.data?.detail ||
      error.message ||
      "Erro desconhecido"
  )

  throw new Error(error.message)
}
