import clsx from "clsx"
import React from "react"
import { ISimpleButton } from "@interfaces/Components/Buttons"
import { Spinner } from "phosphor-react"
import { SimpleButtonSizes } from "@constants/Components/Buttons"
import * as PhotonIcons from "phosphor-react"

const SimpleButton: React.FC<ISimpleButton> = ({
  label,
  isLoading,
  disabled,
  icon,
  badge,
  variant = "primary",
  mode = "solid",
  size = "fitContent",
  ...rest
}) => {
  const hasBadge = badge !== undefined
  const hasIcon = icon !== undefined

  const Icon = (icon && PhotonIcons[icon]) as React.FC<any>

  const styles = {
    primary:
      mode === "solid"
        ? "bg-primary-500 hover:bg-primary-400 text-white"
        : "bg-transparent border border-primary-500 hover:bg-primary-100 text-primary-500",
    secondary:
      mode === "solid"
        ? "bg-secondary-500 hover:bg-secondary-400 text-white"
        : "bg-transparent border border-secondary-500 hover:bg-secondary-100 text-secondary-500",
    white:
      mode === "solid"
        ? "bg-white hover:bg-slate-100 text-primary-500"
        : "bg-transparent border border-slate-200 hover:bg-slate-100 text-slate-500",
  }

  return (
    <button
      {...rest}
      className={clsx(
        "flex justify-center items-center h-fit px-6 py-2 rounded-lg cursor-pointer disabled:cursor-not-allowed disabled:opacity-40 transition-all duration-150",
        styles[variant],
        {
          [SimpleButtonSizes[size]]: size,
          [rest.className as string]: rest.className,
        }
      )}
      disabled={disabled || isLoading}
    >
      {isLoading && hasBadge && !hasIcon && (
        <Spinner
          className={clsx("animate-spin", {
            "mr-2": hasBadge,
          })}
          size={25}
          weight="bold"
        />
      )}
      {icon && <Icon size={25} />}
      <p className="mx-2">{label}</p>
      {badge ? (
        <div className="rounded-full w-7 h-7 text-center bg-black-50 bg-opacity-20 text-white flex justify-center items-center">
          {badge > 99 ? "99+" : badge}
        </div>
      ) : null}
      {isLoading && (!hasBadge || hasIcon) && (
        <Spinner
          className={clsx("animate-spin", {
            "ml-2": hasBadge,
          })}
          size={25}
          weight="bold"
        />
      )}
    </button>
  )
}

export { SimpleButton }
