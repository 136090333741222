import React, { useState, useEffect } from "react"
import clsx from "clsx"

interface CarouselProps {
  images: string[]
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    )
  }

  useEffect(() => {
    const interval = setInterval(goToNext, 5000) // Avança automaticamente a cada 5 segundos
    return () => clearInterval(interval) // Limpa o intervalo ao desmontar
  }, [currentIndex])

  return (
    <div className="relative w-full max-w-4xl mx-auto group">
      <div className="overflow-hidden relative w-full h-64">
        {images.map((image, index) => (
          <div
            key={index}
            className={clsx(
              "absolute transition-transform duration-1000 ease-in-out w-full h-full",
              {
                "translate-x-0": index === currentIndex,
                "translate-x-full": index > currentIndex,
                "-translate-x-full": index < currentIndex,
              }
            )}
          >
            <div className="bg-white p-2 lg:group-hover:opacity-60">
              <img
                className="w-full h-48 object-cover"
                src={image || "/images/no-image.png"}
                alt={`Slide ${index}`}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            className={clsx(
              "w-3 h-3 rounded-full",
              index === currentIndex ? "bg-blue-500" : "bg-gray-400"
            )}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default Carousel
