import React from "react"
import { PaginationProps } from "@interfaces/Components"
import clsx from "clsx"

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  maxVisiblePages = 3,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) return
    onPageChange(page)
  }

  const getPageNumbers = () => {
    const pages = []
    const half = Math.floor(maxVisiblePages / 2)

    let startPage = Math.max(currentPage - half, 1)
    let endPage = Math.min(currentPage + half, totalPages)

    if (currentPage - half < 1) {
      endPage = Math.min(maxVisiblePages, totalPages)
    }
    if (currentPage + half > totalPages) {
      startPage = Math.max(totalPages - maxVisiblePages + 1, 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }

    if (startPage > 1) {
      pages.unshift(1)
      if (startPage > 2) {
        pages.splice(1, 0, -1) // Adiciona o "..." na segunda posição
      }
    }

    if (endPage < totalPages) {
      pages.push(-1) // Adiciona o "..." antes da última página
      pages.push(totalPages)
    }

    return pages
  }

  const pageNumbers = getPageNumbers()

  return (
    <div className="flex items-center justify-center gap-[1px]">
      <button
        className={clsx(
          "rounded-md border border-primary-300 py-1 px-2 text-center text-sm transition-all shadow-sm hover:shadow-lg text-primary-600 hover:text-white hover:bg-primary-500 hover:border-primary-800 focus:text-white focus:bg-primary-500 focus:border-primary-800 active:border-primary-800 active:text-white active:bg-primary-500 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none mr-2",
          {
            "cursor-not-allowed opacity-50": currentPage === 1,
          }
        )}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Anterior
      </button>
      {pageNumbers.map((page) =>
        page === -1 ? (
          <span
            key={Math.random()}
            className="text-lg font-bold text-center pb-2 ml-[6px] m-1 tracking-widest"
          >
            ...
          </span>
        ) : (
          <button
            key={Math.random()}
            className={clsx(
              "min-w-9 rounded-md py-1 px-2 mx-[2px] border text-center text-sm transition-all shadow-sm",
              currentPage === page
                ? "bg-secondary-500 text-white border-transparent hover:bg-secondary-400"
                : "border-primary-300 text-primary-600 hover:text-white hover:bg-primary-500 hover:border-primary-800"
            )}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        )
      )}
      <button
        className={clsx(
          "rounded-md border border-primary-300 py-1 px-2 text-center text-sm transition-all shadow-sm hover:shadow-lg text-primary-600 hover:text-white hover:bg-primary-500 hover:border-primary-800 focus:text-white focus:bg-primary-500 focus:border-primary-800 active:border-primary-800 active:text-white active:bg-primary-500 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ml-2",
          {
            "cursor-not-allowed opacity-50": currentPage === totalPages,
          }
        )}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Próxima
      </button>
    </div>
  )
}

export default Pagination
