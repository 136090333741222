import App from "./App.tsx"
import { Toaster } from "sonner"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ConfigCatProvider } from "configcat-react"
import { queryClient } from "@services/queryClient.ts"
import { QueryClientProvider } from "@tanstack/react-query"
import "./main.css"
import "react-loading-skeleton/dist/skeleton.css"
import "@store"
import { SkeletonTheme } from "react-loading-skeleton"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ConfigCatProvider
    sdkKey={import.meta.env.VITE_CONFIGCAT_SDK_KEY}
    options={{
      pollIntervalSeconds: 15,
    }}
  >
    <Toaster
      position="top-right"
      richColors
      closeButton
      toastOptions={{
        actionButtonStyle: {
          backgroundColor: "var(--secondary-100)",
          color: "#fff",
          fontSize: "14px",
        },
      }}
    />
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SkeletonTheme baseColor="#dadada" highlightColor="#f5f5f5">
          <App />
        </SkeletonTheme>
      </BrowserRouter>
    </QueryClientProvider>
  </ConfigCatProvider>
)
