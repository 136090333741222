import BG from "@assets/bg-3.png"
import FullLogo from "@assets/FullLogo"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import React, { useState } from "react"
import Select from "@components/Inputs/Select"
import useStore from "@store"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { updateMySelf } from "@services/User"
import { useMutation, useQuery } from "@tanstack/react-query"
import { segmentsService } from "@services/Segments"

const FirstAccess: React.FC = ({}) => {
  const { userDetail, setUserDetail } = useStore()
  const [userData, setUserData] = useState({
    name: "",
    lastName: "",
    phone: "",
    segmentId: "",
  })

  const requiredFieldsAreFilled = userData.name && userData.segmentId

  const hasErrorOnPhone = userData.phone && userData.phone.length < 15

  const hasError = hasErrorOnPhone || !requiredFieldsAreFilled

  const { data: segmentsData } = useQuery({
    queryKey: [segmentsService.getSegments.key],
    queryFn: segmentsService.getSegments.fn,
  })

  const { mutate, isPending } = useMutation({
    mutationFn: updateMySelf.fn,
    onSuccess() {
      const findSegment = segmentsData?.data?.find(
        (segment) => segment.id === userData.segmentId
      )

      setUserDetail({
        ...userDetail,
        full_name: `${userData.name} ${userData.lastName}`,
        segment_id: findSegment?.id,
        segment_key: findSegment?.key,
        slug: userDetail?.slug || "",
      })

      toast.success("Cadastro finalizado com sucesso")
    },
  })

  const formattedSegments =
    segmentsData?.data?.map((segment) => ({
      label: segment.name,
      value: segment.id,
    })) || []

  return (
    <div className="w-full h-full flex flex-row items-center justify-between gap-4">
      <FullLogo
        size="medium"
        className="absolute top-4 left-4"
        disableNavigation
      />
      <div className="w-full max-w-xl bg-white rounded-lg p-8 flex flex-col justify-start items-start gap-4 mx-auto">
        <p className="text-gray-800 text-2xl font-bold">
          Seja bem-vindo ao nosso sistema
        </p>
        <p className="text-gray-800 text-lg mb-4">
          Para continuar, precisamos que você complete seu cadastro.
        </p>
        <div className="w-full flex flex-col justify-center">
          <div className="w-full grid grid-cols-2 gap-x-4">
            <InputWithLabel
              label="Nome"
              placeholder="Digite seu nome"
              type="text"
              size="full"
              onType={(value) => {
                setUserData({ ...userData, name: value })
              }}
              isDisabled={isPending}
            />
            <InputWithLabel
              label="Sobrenome (Opcional)"
              placeholder="Digite seu sobrenome"
              type="text"
              size="full"
              onType={(value) => {
                setUserData({ ...userData, lastName: value })
              }}
              isDisabled={isPending}
            />
          </div>
          <div className="w-full grid grid-cols-1 gap-x-4">
            <Select
              label="Segmento"
              placeholder="Selecione o segmento"
              options={formattedSegments}
              onSelectOption={({ value }) => {
                setUserData({ ...userData, segmentId: value })
              }}
              className="w-full mb-2"
              isDisabled={isPending}
            />
            <InputWithLabel
              label="Telefone (Opcional)"
              placeholder="Digite seu telefone"
              type="text"
              size="full"
              masks="phone"
              error={hasErrorOnPhone ? "Telefone inválido" : ""}
              onType={(value) => {
                setUserData({ ...userData, phone: value })
              }}
              isDisabled={isPending}
            />
          </div>
        </div>
        <div className="w-full flex justify-end">
          <SimpleButton
            label="Continuar"
            onClick={() => {
              mutate({
                full_name: `${userData.name} ${userData.lastName}`,
                segment_id: userData.segmentId,
                phone_number: userData.phone,
              })
            }}
            isLoading={isPending}
            disabled={hasError}
          />
        </div>
      </div>
      <div className="hidden w-11/12 h-full bg-gray-200 lg:flex flex-col items-center justify-center rounded-lg shadow-lg p-4 relative overflow-hidden">
        <img
          src={BG}
          alt="Landing"
          className=" absolute top-12 left-12 rounded-3xl"
        />
        {/* overlay bg-gradient */}
        <div className="w-full h-full absolute top-12 left-12 rounded-3xl z-10 bg-gradient-to-br from-[#ffffff00] to-[#f0f0f0]" />
      </div>
    </div>
  )
}

export default FirstAccess
