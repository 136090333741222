import {
  IListProductsParams,
  ICreateProductParams,
  IListProducts,
  IEditProductParams,
} from "@interfaces/Services"
import { clientApi } from "./clientApi"
import { toast } from "sonner"

const productsService = {
  getProductDetails: {
    fn: async (id: string) => {
      try {
        const { data } = await clientApi.get(`/products/${id}`)

        return data
      } catch (error: any) {
        toast.error("Erro ao buscar: " + error.message)
        throw new Error(error)
      }
    },
    key: "getProductDetails",
  },
  listProducts: {
    fn: async (filters: IListProductsParams) => {
      try {
        const { data } = await clientApi.get(`/products`, {
          params: {
            name: filters.name,
            page: filters.page,
            type: filters.type,
            is_available: filters.isAvailable,
            is_release: filters.isRelease,
          },
        })

        return data
      } catch (error: any) {
        toast.error("Erro ao buscar: " + error.message)
        throw new Error(error)
      }
    },
    key: "listProducts",
  },
  createProduct: {
    fn: async (data: ICreateProductParams) => {
      const formData = new FormData()

      Object.entries(data).forEach(([key, value]) => {
        if (key === "files") {
          value.forEach((file: File) => {
            formData.append("files", file)
          })
        } else if (key === "sales") {
          formData.append("sales", JSON.stringify(value))
        } else if (key === "custom_fields") {
          formData.append("custom_fields", JSON.stringify(value))
        } else {
          formData.append(key, value)
        }
      })

      try {
        const { data: response } = await clientApi.post(`/products`, formData)

        return response
      } catch (error: any) {
        toast.error("Erro ao criar: " + error.message)
        throw new Error(error)
      }
    },
    key: "createProduct",
  },
  updateProduct: {
    fn: async (data: IEditProductParams) => {
      const formData = new FormData()

      Object.entries(data).forEach(([key, value]) => {
        if (key === "files") {
          formData.append("files", "[]")
          value.forEach((file: File) => {
            if (file instanceof File) {
              formData.append("files", file)
            }
          })
        } else if (key === "removed_image_urls") {
          formData.append("removed_image_urls", JSON.stringify(value))
        } else if (key === "sales") {
          formData.append("sales", JSON.stringify(value))
        } else if (key === "custom_fields") {
          formData.append("custom_fields", JSON.stringify(value))
        } else {
          formData.append(key, value)
        }
      })

      try {
        const { data: response } = await clientApi.put(
          `/products/${data.id}`,
          formData
        )

        return response
      } catch (error: any) {
        toast.error("Erro ao atualizar: " + error.message)
        throw new Error(error)
      }
    },
    key: "updateProduct",
  },
  deleteProduct: {
    fn: async (id: string) => {
      try {
        const { data } = await clientApi.delete(`/products/${id}`)

        return data
      } catch (error: any) {
        toast.error("Erro ao deletar: " + error.message)
        throw new Error(error)
      }
    },
    key: "deleteProduct",
  },
}
export { productsService }

export type { ICreateProductParams, IListProducts }
