import FirstAccess from "@pages/Private/FirstAccess"
import Loading from "@components/Loading"
import Private from "./Routes/Private"
import PrivateHOC from "./Components/PrivateHOC"
import Public from "./Routes/Public"
import React from "react"
import useStore from "@store"
import { autoRefreshSession, decodeHash } from "./Utils/autoRefreshSession"
import { getUserDetails } from "@services/User"
import { localStorageAuthKey } from "./Constants/Auth"
import { publicRoutes } from "@constants/Routes"
import { toast } from "sonner"
import { useLocation } from "react-router-dom"
import { segmentsService } from "@services/Segments"

const publicRoutesAccess = [publicRoutes.products]

const App: React.FC = () => {
  const location = useLocation()
  const path = location.pathname

  const { isLogged, setIsLogged, userDetail, setUserDetail } = useStore()

  const { hash } = useLocation()

  const routeIsSplashScreen = window.location.pathname === "/splash-screen"

  const decodedHash = hash && decodeHash(hash)

  if (decodedHash) {
    localStorage.setItem(localStorageAuthKey, JSON.stringify(decodedHash))
  }

  const dontHasLogin = isLogged === undefined

  const isLoadingPlatform = dontHasLogin

  const searchUserDetails = async () => {
    try {
      const data = await getUserDetails.fn()
      const segmentDetail =
        data?.segment_id &&
        (await segmentsService.getSegment.fn(data.segment_id as string))

      setIsLogged(true)
      setUserDetail({
        ...data,
        segment_key: segmentDetail ? segmentDetail.key : undefined,
      })

      return data
    } catch (error: any) {
      toast.error("Erro ao buscar detalhes do usuário")
    }
  }

  if (
    isLoadingPlatform &&
    !routeIsSplashScreen &&
    !decodedHash &&
    !userDetail?.id
  ) {
    autoRefreshSession({
      onSucess: () => {
        searchUserDetails()
      },
      onFail: () => {
        setIsLogged(false)
      },
    })
    return (
      <div className="bg-white w-screen h-screen flex flex-col justify-center items-center">
        <p className="text-primary-500 text-2xl font-bold mb-8">
          Carregando plataforma, aguarde...
        </p>
        <Loading iconLoading="ClipboardText" size={160} />
      </div>
    )
  }

  const isUserFirstAccess = !userDetail?.segment_id || !userDetail?.full_name

  if (publicRoutesAccess.includes(path)) {
    return <Public />
  }

  if (!isLogged) {
    return <Public />
  }

  if (isLogged && isUserFirstAccess) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <FirstAccess />
      </div>
    )
  }

  if (isLogged && !isUserFirstAccess) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <PrivateHOC>
          <Private />
        </PrivateHOC>
      </div>
    )
  }
}

export default App
