import BG from "@assets/bg.png"
import FullLogo from "@assets/FullLogo"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import React, { useEffect, useRef, useState } from "react"
import Separator from "@components/Separator"
import useStore from "@store"
import { autoRefreshSession, saveLoginData } from "@utils/autoRefreshSession"
import { ITokenData, loginUser } from "@services/SignIn/loginUser"
import { privateRoutes, publicRoutes } from "@constants/Routes"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { timeToRefresh } from "@constants/Auth"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

const SignIn: React.FC = ({}) => {
  const navigate = useNavigate()
  const { setIsLogged } = useStore()

  const [hasGoogleScriptLoaded] = useState(false)
  const [newUserData, setNewUserData] = useState({
    email: "",
    password: "",
  })

  const handleChangeUserData = (field: string, value: string) => {
    setNewUserData({
      ...newUserData,
      [field]: value,
    })
  }

  const onSuccess = (tokenData: ITokenData) => {
    const hasShowSplashScreenToday = new Date(
      localStorage.getItem("hasShowSplashScreenToday") || ""
    ).toDateString()
    const dateToday = new Date().toDateString()

    saveLoginData({ tokenData })

    if (hasShowSplashScreenToday !== dateToday) {
      localStorage.setItem(
        "hasShowSplashScreenToday",
        new Date().toDateString()
      )
      navigate(publicRoutes.splashScreen)
      setTimeout(() => {
        autoRefreshSession({
          onSucess: () => {},
          onFail: () => {
            setIsLogged(false)
            navigate(publicRoutes.landing)
          },
        })
      }, timeToRefresh)
    } else {
      window.location.href = privateRoutes.dashboard
    }
  }

  const { mutate: loginUserMutation, isPending: isLoggingIn } = useMutation({
    mutationFn: loginUser.fn,
    mutationKey: [loginUser.key],
    onSuccess,
    onError: () => {},
  })

  const hasFormError = {
    email: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(
      newUserData.email
    ),
    password: newUserData.password.length > 5,
  }
  const hasEmailError = newUserData.email !== "" && !hasFormError.email
  const hasPasswordError = newUserData.password !== "" && !hasFormError.password

  const isFieldsEmpty = newUserData.email === "" || newUserData.password === ""

  const googleButtonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const iframe = googleButtonRef.current?.querySelector("iframe")

    if (iframe) {
      iframe.style.width = "100%"
      iframe.style.height = "50px"
      iframe.style.top = "-3px"
      iframe.style.left = "10px"
    }
  }, [hasGoogleScriptLoaded, googleButtonRef])

  return (
    <div className="bg-[#fafafa] w-screen h-screen flex items-center justify-center">
      <div className="w-full lg:w-5/12 h-full p-4 bg-white flex flex-col items-center justify-start rounded-lg shadow-lg">
        <div className="w-full flex items-center justify-start gap-1 mb-8 lg:mb-4">
          <FullLogo size="small" />
        </div>
        <div className="w-full max-w-sm h-full flex flex-col items-center justify-center gap-4">
          <div className="w-full flex flex-col items-start justify-center">
            <p className="text-2xl font-semibold text-gray-700">
              Faça seu login
            </p>
            <p className="text-lg font-medium text-gray-700">
              Gerencie seus pedidos de forma fácil e rápida
            </p>
          </div>
          <Separator middleText="ou" size="medium" />
          <div className="w-full flex flex-col items-center justify-center gap-1">
            <InputWithLabel
              label="E-mail"
              type="email"
              placeholder="Digite seu e-mail"
              value={newUserData.email}
              onType={(value) => handleChangeUserData("email", value)}
              size="full"
              error={hasEmailError ? "E-mail inválido" : ""}
            />
            <InputWithLabel
              label="Senha"
              type="password"
              placeholder="Digite sua senha"
              value={newUserData.password}
              onType={(value) => handleChangeUserData("password", value)}
              size="full"
              onEnter={() => {
                if (!isFieldsEmpty && !hasEmailError && !hasPasswordError) {
                  loginUserMutation(newUserData)
                }
              }}
              error={hasPasswordError ? "Senha inválida" : ""}
            />
            <div className="w-full mb-4">
              <SimpleButton
                label="Entrar"
                size="full"
                onClick={() => {
                  loginUserMutation(newUserData)
                }}
                disabled={hasEmailError || hasPasswordError || isFieldsEmpty}
                isLoading={isLoggingIn}
              />
            </div>

            <div className="text-base text-gray-700 cursor-pointer text-center">
              <p>Ainda não tem uma conta? </p>
              <p
                className="text-primary-500 font-black cursor-pointer hover:underline hover:text-primary-200"
                onClick={() => {
                  navigate(publicRoutes.signUp)
                }}
              >
                Crie uma agora mesmo!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden w-7/12 h-full bg-gray-200 lg:flex flex-col items-center justify-center rounded-lg shadow-lg p-4 relative overflow-hidden">
        <img
          src={BG}
          alt="Landing"
          className=" absolute top-12 left-12 rounded-3xl"
        />
        {/* overlay bg-gradient */}
        <div className="w-full h-full absolute top-12 left-12 rounded-3xl z-10 bg-gradient-to-br from-[#ffffff00] to-[#ffffff]" />
      </div>
    </div>
  )
}

export default SignIn
