import clsx from "clsx"
import Popup from "reactjs-popup"
import React from "react"
import Tooltip from "@components/Tooltip"
import { formatNumberToMoney } from "@utils/money"
import { IProductResponse } from "@interfaces/Services"
import { RocketLaunch, ShoppingCart, X } from "phosphor-react"
import Carousel from "@components/Caroussel"

interface ICard {
  isOnCart?: boolean
  product: IProductResponse
  countInCart: number
  handleOpenDrawer?: () => void
  onAddCart?: (item: any) => void
  onRemoveCart?: (item: any) => void
}

export const Card: React.FC<ICard> = ({
  isOnCart,
  product,
  countInCart = 0,
  handleOpenDrawer,
  onRemoveCart,
  onAddCart,
}) => {
  const { id, price, sale_price, is_release, sales, name } = product

  return (
    <div
      className={clsx(
        "overflow-hidden relative bg-white border shadow-lg flex flex-col items-center p-2 rounded hover:shadow-xl lg:hover:scale-105 transition-all ease-linear group",
        isOnCart ? "border border-secondary-100" : "border border-gray-200"
      )}
    >
      <div className="w-full flex justify-end">
        <Popup
          on={["hover"]}
          position={["top center"]}
          trigger={
            <span
              className={clsx(
                "w-fit opacity-0 self-end border-[1px] border-primary-500 bg-primary-500 text-sm font-semibold text-white px-2 py-1 rounded",
                is_release && "opacity-100"
              )}
            >
              <RocketLaunch size={18} />
            </span>
          }
        >
          <div className="border border-primary-500 bg-white p-2 rounded-md text-primary-500 mb-2">
            <p>Este produto é um lançamento.</p>
          </div>
        </Popup>
        {countInCart > 0 ? (
          <span
            className={clsx(
              "flex w-fit opacity-0 self-end border-[1px] border-primary-500 text-primary-500 text-sm font-semibold px-2 py-1 rounded ml-2",
              countInCart > 0 && "opacity-100"
            )}
            onClick={handleOpenDrawer}
          >
            <ShoppingCart size={18} className="mr-1" />
            {countInCart}
          </span>
        ) : null}
      </div>

      <Carousel images={product.image_urls || []} />

      <div className="w-full h-full flex flex-col border-t border-slate-200 rounded-bl-md rounded-br-md p-4 gap-2">
        {/* <span className="font-medium text-sm">
          {type === "generic" ? "Genérico" : "Referência"}
        </span> */}
        <strong className="font-semibold text-lg">
          {name.length > 40 ? (
            <Tooltip id={id} text={name}>
              <span className="block truncate">{name}</span>
            </Tooltip>
          ) : (
            <>{name}</>
          )}
        </strong>

        <div className="flex items-center text-xl font-bold w-full mb-2">
          <p
            className={clsx(
              sale_price !== 0
                ? "text-gray-400 line-through mr-2 text-base"
                : "text-sky-500"
            )}
          >
            {formatNumberToMoney(price)}
          </p>

          {sale_price !== 0 && (
            <p className="text-sky-500">{formatNumberToMoney(sale_price)}</p>
          )}

          {sales.length > 0 && (
            <Popup
              on={["hover"]}
              position={["top right"]}
              trigger={
                <div
                  className="bg-primary-500 text-white rounded-lg px-3 py-1 text-xs absolute bottom-2
                 right-2"
                >
                  {"Promoção".split("").map((char, index) => (
                    <span
                      key={index}
                      className="animate-pulse"
                      style={{ animationDelay: `${index * 0.2}s` }}
                    >
                      {char}
                    </span>
                  ))}
                </div>
              }
            >
              <div className="bg-white px-2 shadow-xl border-2 rounded-md">
                {sales.map((sale) => (
                  <div className="my-2 flex items-center p-2 bg-primary-500 rounded text-white text-sm font-semibold">
                    <span>
                      Acima de {sale.from}, por{" "}
                      {formatNumberToMoney(sale.price)}
                    </span>
                  </div>
                ))}
              </div>
            </Popup>
          )}
        </div>
      </div>

      <div
        className={clsx(
          "absolute top-2 flex flex-col left-2 lg:left-[-66px] lg:group-hover:left-2 transition-all duration-300 ease-in-out gap-2"
        )}
      >
        <div
          className="bg-white border border-gray-300 p-2 rounded hover:bg-primary-500 cursor-pointer hover:text-white transition-all flex"
          onClick={() => {
            onAddCart && onAddCart(product)
          }}
        >
          <ShoppingCart size={20} />
          {isOnCart && <p className="ml-1 font-semibold">+ 1</p>}
        </div>
        {isOnCart && (
          <div
            className="w-fit bg-white border border-gray-300 p-2 rounded active:bg-primary-500 cursor-pointer active:text-white transition-all"
            onClick={() => {
              onRemoveCart && onRemoveCart(product)
            }}
          >
            <X size={20} />
          </div>
        )}
      </div>
    </div>
  )
}
