import { ArrowCircleLeft, ArrowCircleRight } from "phosphor-react"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IImageWithView {
  imagesUrl?: string[]
  alt?: string
  size?: "small" | "medium" | "large"
}

const sizes = {
  small: "w-8 h-8",
  medium: "w-12 h-12",
  large: "w-32 h-32",
}

const ImageWithView: React.FC<IImageWithView> = ({
  imagesUrl = [],
  alt,
  size = "small",
}) => {
  const [isOpenView, setIsOpenView] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const viewRef = React.useRef<HTMLDivElement>(null)

  const hasMultipleImages = imagesUrl.length > 1

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (viewRef.current && e.target === viewRef.current) {
        setIsOpenView(false)
      }
    }

    if (isOpenView) {
      document.addEventListener("click", handleClickOutside)
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setIsOpenView(false)
        }
      })
    } else {
      document.removeEventListener("click", handleClickOutside)
    }

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [isOpenView])

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesUrl.length)
  }

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imagesUrl.length) % imagesUrl.length
    )
  }

  const modalContent = (
    <div
      ref={viewRef}
      className="absolute top-0 z-[99999999999999999999999999999999999999999] bg-black bg-opacity-50 w-full h-full flex items-center justify-center"
      style={{
        display: isOpenView ? "flex" : "none",
      }}
    >
      <div className="relative">
        {hasMultipleImages && (
          <button
            onClick={handlePrev}
            className="absolute -left-14 top-1/2 transform -translate-y-1/2"
          >
            <ArrowCircleLeft size={48} className="text-white" />
          </button>
        )}
        <img
          src={imagesUrl[currentIndex] || "https://via.placeholder.com/50"}
          alt={alt}
          className="aspect-auto max-w-[800px] rounded-lg"
        />
        {hasMultipleImages && (
          <button
            onClick={handleNext}
            className="absolute -right-14 top-1/2 transform -translate-y-1/2"
          >
            <ArrowCircleRight size={48} className="text-white" />
          </button>
        )}
      </div>
    </div>
  )

  return (
    <>
      {ReactDOM.createPortal(modalContent, document.body)}
      <img
        src={imagesUrl[0] || "https://via.placeholder.com/50"}
        alt={alt}
        className={`cursor-pointer ${sizes[size]}`}
        onClick={() => setIsOpenView(!isOpenView)}
      />
    </>
  )
}

export default ImageWithView
