/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGetSegmentsData, ICustomFields } from "@interfaces/Services"
import { clientApi } from "./clientApi"

const segmentsService = {
  getSegments: {
    fn: async () => {
      try {
        const { data } = await clientApi.get<IGetSegmentsData>("/segments")

        return data
      } catch (error: any) {
        throw new Error(error.message)
      }
    },
    key: "getSegments",
  },
  getSegment: {
    fn: async (segmentId: string) => {
      try {
        const { data } = await clientApi.get<{
          key: string
        }>(`/segments/${segmentId}`)

        return data
      } catch (error: any) {
        throw new Error(error.message)
      }
    },
    key: "getSegment",
  },
  getSegmentsCustomFields: {
    fn: async (segmentId: string) => {
      try {
        const { data } = await clientApi.get<ICustomFields[]>(
          `/segments/custom_fields/${segmentId}`
        )

        return data
      } catch (error: any) {
        throw new Error(error.message)
      }
    },
    key: "getSegmentsCustomFields",
  },
}

export { segmentsService }
