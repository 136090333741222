import * as yup from "yup"
import Card from "@components/Card"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import React, { useState } from "react"
import UnderlineTitle from "@components/UnderlineTitle"
import useStore from "@store"
import { Masks } from "@constants/Masks"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { updateMySelf } from "@services/User"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

const Profile: React.FC = ({}) => {
  const { userDetail } = useStore()

  const [canUpdate, setCanUpdate] = useState(true)

  const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    slug: yup.string().required("Slug é obrigatório"),
    phone: yup.string(),
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: userDetail?.full_name || "",
      slug: userDetail?.slug || "",
      phone: Masks.phone(userDetail?.phone_number || ""),
    },
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods

  const disableSubmit = !isValid

  const { mutate: updateUser, isPending } = useMutation({
    mutationFn: updateMySelf.fn,
    onSuccess() {
      toast.success("Usuário atualizado com sucesso")
      setCanUpdate(false)
      setTimeout(() => {
        setCanUpdate(true)
      }, 30000)
    },
  })

  return (
    <>
      <UnderlineTitle />
      <Card className="w-full max-w-xl">
        <p className="text-lg font-semibold text-primary-500 mb-4">
          Informações
        </p>
        <div className="grid grid-cols-2 gap-4">
          <InputWithLabel
            label="Nome"
            size="full"
            placeholder="Nome"
            error={errors?.name?.message}
            register={register("name")}
          />
          <InputWithLabel
            label="Slug"
            size="full"
            placeholder="Slug"
            error={errors?.slug?.message}
            register={register("slug")}
          />
          <InputWithLabel
            label="Telefone"
            size="full"
            placeholder="Telefone"
            error={errors?.phone?.message}
            masks="phone"
            register={register("phone")}
          />
        </div>
        <div className="flex justify-end">
          <SimpleButton
            label="Salvar"
            className="mt-4"
            disabled={disableSubmit || !canUpdate}
            isLoading={isPending}
            onClick={handleSubmit((data) => {
              updateUser({
                full_name: data.name,
                slug: data.slug,
                phone_number: data.phone,
                segment_id: userDetail?.segment_id,
              })
            })}
          />
        </div>
      </Card>
    </>
  )
}

export default Profile
