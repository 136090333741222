import { Field } from "@interfaces/Pages/Products"
import { formatStringMoneyToNumber } from "@utils/money"
import * as yup from "yup"

export const fieldsProduct: Field[] = [
  {
    label: "Nome do produto",
    key: "name",
    info: "",
    placeholder: "Nome do produto",
    size: "full",
    isRequired: true,
  },
  {
    label: "Preço",
    key: "price",
    info: "",
    placeholder: "Preço",
    size: "full",
    masks: "money",
    isRequired: true,
  },
  {
    label: "Preço Promocional",
    key: "salePrice",
    info: "Ao adicionar um preço promocional, o produto será exibido com o preço promocional.",
    placeholder: "Preço Promocional",
    size: "full",
    masks: "money",
    isRequired: false,
    customSchema: yup
      .string()
      .test(
        "is-lower-than-price",
        "O preço promocional deve ser menor que o preço padrão",
        function (value) {
          const price = formatStringMoneyToNumber(this.parent.price)
          const salePrice = formatStringMoneyToNumber(value)

          return salePrice < price
        }
      ),
  },
]

export const headersBySegment: {
  [key: string]: {
    key: string
    title: string
    size: number
    isCustom?: boolean
    isSortable?: boolean
  }[]
} = {
  drugs: [
    {
      key: "name",
      title: "Nome",
      size: 100,
      isCustom: true,
      isSortable: true,
    },
    { key: "price", title: "Preço", size: 100, isSortable: true },
    {
      key: "sale_price",
      title: "Preço Promocional",
      size: 100,
      isSortable: true,
    },
    {
      key: "is_available",
      title: "Disponível",
      size: 100,
      isCustom: true,
      isSortable: true,
    },
    {
      key: "is_release",
      title: "Lançamento",
      size: 100,
      isCustom: true,
      isSortable: true,
    },
    { key: "link", title: "Info. do fabricante", size: 100, isCustom: true },
    {
      key: "actions",
      title: "Ações",
      size: 100,
      isCustom: true,
    },
  ],
}

export const tableVisibleColumnsBySegment = {
  drugs: headersBySegment.drugs.map((header) => header.key),
}
