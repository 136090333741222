/* eslint-disable @typescript-eslint/no-explicit-any */
import { localStorageAuthKey, timeToRefresh } from "@constants/Auth"
import { ITokenData } from "@services/SignIn/loginUser"
import { clientApi } from "@services/clientApi"
import { refreshToken } from "./refreshToken"
import { decryptToken, ITokenDataDecoded } from "./decryptToken"
import { toast } from "sonner"

interface SuccessLoginProps {
  tokenData: ITokenData
}

export const saveLoginData = ({ tokenData }: SuccessLoginProps) => {
  localStorage.setItem(localStorageAuthKey, JSON.stringify(tokenData))
  clientApi.defaults.headers.Authorization = `Bearer ${tokenData.access_token}`
}

export const errorLogin = () => {
  localStorage.removeItem(localStorageAuthKey)
}

export const decodeHash = (hashParam: string) => {
  const separatedHash = hashParam?.split?.("#")?.[1]?.split?.("&")

  return (
    separatedHash &&
    separatedHash?.reduce((acc, curr) => {
      const [key, value] = curr.split("=")
      acc[key as keyof ITokenData] = value
      return acc
    }, {} as ITokenData)
  )
}

interface AutorefreshTokenProps {
  onSucess?: (decodedToken?: ITokenDataDecoded) => void
  onFail?: () => void
}

export const autoRefreshSession = async ({
  onSucess,
  onFail,
}: AutorefreshTokenProps) => {
  const authKey = localStorage.getItem(localStorageAuthKey)

  const hasAuthKey = authKey && authKey !== "undefined"

  const convertedTokenJsonToObject = hasAuthKey
    ? (JSON.parse(authKey) as ITokenData)
    : null

  if (!hasAuthKey || !convertedTokenJsonToObject) {
    onFail?.()
    return
  }

  const decodedToken = decryptToken(convertedTokenJsonToObject)

  if (!decodedToken) {
    try {
      const responseRefreshToken = await refreshToken({
        refresh_token: convertedTokenJsonToObject.refresh_token,
      })

      if (!responseRefreshToken) {
        throw new Error("Error to refresh token")
      }

      const newTokenDecoded = decryptToken(responseRefreshToken)

      if (!newTokenDecoded) {
        errorLogin()
        onFail?.()
        return toast.error("Error to refresh token")
      }

      saveLoginData({ tokenData: responseRefreshToken })
      onSucess?.(newTokenDecoded)

      setTimeout(() => {
        autoRefreshSession({ onSucess, onFail })
      }, timeToRefresh)
    } catch (error: any) {
      errorLogin()
      onFail?.()
    }
  } else {
    saveLoginData({ tokenData: convertedTokenJsonToObject })
    setTimeout(() => {
      autoRefreshSession({ onSucess, onFail })
    }, timeToRefresh)
    onSucess?.(decodedToken)
  }
}
