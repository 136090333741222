import { fieldsProduct } from "@constants/Products"
import { Field } from "@interfaces/Pages/Products"
import { ICustomFields } from "@interfaces/Services"
import * as yup from "yup"

export const createSchemaToCustomFields = (customFields: ICustomFields[]) => {
  return customFields.reduce((acc, field) => {
    let currentField = yup.string()

    if (field.is_required) {
      currentField = currentField.required("Campo obrigatório")
    }

    if (field.min_length) {
      currentField = currentField.min(
        field.min_length,
        `Mínimo de ${field.min_length} caracteres`
      )
    }

    if (field.max_length) {
      currentField = currentField.max(
        field.max_length,
        `Máximo de ${field.max_length} caracteres`
      )
    }

    if (field.pattern) {
      currentField = currentField.test(
        "pattern",
        field.validateMessage || "Campo inválido",
        (value) => {
          if (!value) return true

          return field.pattern ? new RegExp(field.pattern).test(value) : true
        }
      )
    }

    acc[field.key] = currentField

    return acc
  }, {} as any)
}

export const createSchemaToBaseFields = (fields: Field[]) => {
  return fields.reduce((acc, field) => {
    let currentField = yup.string()

    if (field.isRequired) {
      currentField = currentField.required("Campo obrigatório")
    }

    if (field.customSchema) {
      currentField = field.customSchema
    }

    acc[field.key] = currentField

    return acc
  }, {} as any)
}

// Form validation schema
export const newProductSchema = (customFields: ICustomFields[]) =>
  yup
    .object({
      sales: yup.array().of(
        yup.object({
          from: yup.string().required(),
          price: yup.string().required(),
        })
      ),
      isRelease: yup.boolean(),
      isAvailable: yup.boolean(),
      ...createSchemaToBaseFields(fieldsProduct),
      ...createSchemaToCustomFields(customFields),
    })
    .required()
