/* eslint-disable @typescript-eslint/no-explicit-any */
import { localStorageAuthKey } from "@constants/Auth"
import { clientApi } from "./clientApi"
import { publicRoutes } from "@constants/Routes"
import { IGetUserDetailsData, IUpdateMySelfData } from "@interfaces/Services"

const getUserDetails = {
  fn: async () => {
    try {
      const { data } = await clientApi.get<IGetUserDetailsData>("/profiles/me")

      return data
    } catch (error: any) {
      throw new Error(error.message)
    }
  },
  key: "getUserDetails",
}

const updateMySelf = {
  fn: async (data: IUpdateMySelfData) => {
    try {
      const response = await clientApi.put("/profiles/me", data)

      return response
    } catch (error: any) {
      throw new Error(error.message)
    }
  },
  key: "updateMySelf",
}

const logout = {
  fn: async () => {
    try {
      localStorage.removeItem(localStorageAuthKey)
      window.location.href = publicRoutes.signIn

      return true
    } catch (error: any) {
      throw new Error(error.message)
    }
  },
  key: "logout",
}

export { getUserDetails, updateMySelf, logout }
