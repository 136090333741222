import { sidebarMenu } from "@constants/Routes"
import clsx from "clsx"
import { ArrowCircleLeft } from "phosphor-react"
import React, { useState } from "react"
import SideBarOption from "./SideBarOption"

interface ISideBarMobile {}

const SideBarMobile: React.FC<ISideBarMobile> = ({}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <>
      <div
        className={clsx(
          "lg:hidden h-full absolute w-64 bg-white shadow-xl transition-all duration-700 rounded-b-3xl z-30 p-2",
          isCollapsed ? "left-[-256px]" : "left-0"
        )}
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
      >
        <div
          className={clsx(
            "h-full lg:h-[calc(100vh-230px)] flex flex-col justify-start items-start gap-2 overflow-hidden"
          )}
        >
          {sidebarMenu().map((option, index) => (
            <SideBarOption key={index} isCollapsed={isCollapsed} {...option} />
          ))}
        </div>
        <ArrowCircleLeft
          weight="fill"
          className={clsx(
            "absolute bottom-5 text-primary-500 cursor-pointer transition-all duration-700",
            isCollapsed ? "rotate-180 right-[-25px]" : "right-5"
          )}
          size={38}
        />
      </div>
    </>
  )
}

export default SideBarMobile
