import { IGetUserDetailsData } from "@interfaces/Services"
import { create } from "zustand"

interface IStore {
  userDetail?: IGetUserDetailsData
  setUserDetail: (userDetail: IGetUserDetailsData) => void
  isLogged?: boolean
  setIsLogged: (isLogged: boolean) => void
}

const useStore = create<IStore>()((saveState) => {
  return {
    setUserDetail: (userDetail) => saveState({ userDetail }),
    setIsLogged: (isLogged) => saveState({ isLogged }),
  }
})

export default useStore
